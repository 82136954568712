<div class="container">
  <div class="instMyCouncilModal">
    <div class="container">
      <div class="row">
        <div class="col-md-12"
          style="background-color: #fff;color: #000 !important;font-size: 27px;">
          <p class="hdr bifuracationPara">Performance Report for the Academic Year 2023-24</p>
          <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Performance Report for the Academic Year 2021-22</b></li> -->
              <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li> -->
              <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">
                <div class="row">
                  <div class="col-md-12 NewScoreList">
                    <span class="mic_btn mic_totalmarks">Total Score: {{formData?.total_marks}}/100</span>
                    <span class="mic_btn mic_rewardpoints">Total Reward: {{formData?.totalRewards}}</span>
                    <!-- <span class="mic_btn mic_rating1">Rating: {{formData?.rating}}/4</span>
                    <span class="mic_btn mic_rating2">Fifth Star Rating: {{fstarScore?.star_rating}}/1</span> -->
                    <span class="mic_btn mic_rating">Final Star: {{formData?.final_star}}/5</span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- <p style="line-height:1.1; text-align:center;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC-Institute earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star</p> -->

            <table id="scoreActivity" class="table table-hover" style="margin-bottom: 0px;border:1px solid #ffffff;">
              <tbody style="text-align: center;">
                <tr>
                  <td style="text-align:center; vertical-align:top;"><strong>Activity Category</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total No of Submited Activities&nbsp;</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total No of Approved Activities&nbsp;</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>*Disapproval %&nbsp; of Activities</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; for Q1-Q4 Activities (I = A*80%)</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; from Participation (II = B*20%)</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; (I + II = 100)</strong></td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">IIC Calendar Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.iicAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.iicAllApproved}}</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{ (fourthStarScore) ? fourthStarScore?.disapproved_percentage : 0 }}%</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{ (formData) ? formData?.four_star_total_eighty : 0 }}</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{ (formData) ? formData?.fifth_star_score : 0 }}</td>                  
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{formData?.total_marks}}</td>
                </tr> 
                <tr>
                  <td style="text-align:center; vertical-align:top;">MIC driven Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.micAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.micAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">Self-driven Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.selfAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.selfAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">Celebration Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.celAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.celAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;"><strong>Total</strong></td>
                  <td style="text-align:center; vertical-align:top;">{{ (formData) ? formData?.TotalActivitySubmitted_new : 0 }}</td>
                  <td style="text-align:center; vertical-align:top;">{{ (fourthStarScore) ? fourthStarScore?.total_approved : 0 }}</td>                  
                </tr>
              </tbody>
            </table>
            <!-- <table id="scoreActivity" class="table table-hover" style="margin-bottom: 0px;border:1px solid #ffffff;">
              <tbody style="text-align: center;">
              <tr>
                <td style="text-align:center; vertical-align:top;"><strong>Activity Summary</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Score Earned&nbsp; for Q1-Q4 Activities (I = A*80%)</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Score Earned&nbsp; from Participation (II = B*20%)</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; (I + II = 100)</strong></td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Total no of Submitted Activities : {{ (formData) ? formData?.TotalActivitySubmitted_new : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><strong>Total Score Earned  for Q1-Q4 Activities :</strong> {{ (formData) ? formData?.four_star_total : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><strong>Total Score Earned  from Participation :</strong> {{ (formData) ? fstarScore?.total_score : 0 }}</td>                  
                <td style="text-align:center; vertical-align:top;" rowspan="6">{{formData?.total_marks}}</td>
              </tr> 
              <tr>
                <td style="text-align:center; vertical-align:top;" >Total IA Verified Report : {{ (fstarScore) ? (fstarScore?.ia_online_selfdriven + fstarScore?.ia_offline_selfdriven) : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;" >No of Approved Activities : {{ (fourthStarScore) ? fourthStarScore?.total_approved : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">No of Disapproved Activities : {{ (fourthStarScore) ? ((formData?.TotalActivitySubmitted_new + (fstarScore?.ia_online_selfdriven + fstarScore?.ia_offline_selfdriven)) - fourthStarScore?.total_approved) : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><b>I.  </b><strong>Total Score Earned  for Q1-Q4 Activities (I = A*80%) :</strong> {{ (formData) ? formData?.four_star_total_eighty : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><b>II.  </b><strong>Total Score Earned  from Participation (II = B*20%) :</strong> {{ (formData) ? formData?.fifth_star_score : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Disapproval % of Activities : {{ (fourthStarScore) ? fourthStarScore?.disapproved_percentage : 0 }}</td>
                
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Reward Point Earned : {{ (fourthStarScore) ? fourthStarScore?.totalRewards : 0 }}</td>
              </tr>
            </tbody>
          </table> -->

          <table style="text-align: center;" class="table table-hover">
            <tbody>
                <tr  class="trheadingactivity">
                    <td colspan="12"><strong>Table A: Unit Score/Activity (Q1-Q4)</strong></td>
                </tr>
                <tr class="triicactivity">
                    <td><strong>S. No.</strong></td>
                    <td><strong>Activity Category</strong></td>
                    <td><strong>Activity Level</strong></td>
                    <td><strong>No. of Prescribed Activities</strong></td>
                    <td><strong>Max Allocation Score</strong></td>
                    <td><strong>No of Threshold Activties</strong></td>
                    <td><strong>Unit Score/Activity&nbsp;</strong></td>
                    <td><strong>Unit Score/Activity (Offline)&nbsp;</strong></td>
                    <td><strong>Unit Score/Activity (Online)&nbsp;</strong></td>
                    <td><strong>Total No of Submitted Activities</strong></td>
                    <td><strong>No of Approved Activities</strong></td>
                    <td><strong>Total Score Earned for Q1-Q4 Activities</strong></td>
                </tr>
                <tr class="triicactivity">
                    <td rowspan="4">1</td>
                    <td rowspan="4">IIC&nbsp; Calendar Activities</td>
                    <td rowspan="2">Level 1 & 2</td>
                    <td rowspan="2">15</td>
                    <td rowspan="2">15.00</td>
                    <td rowspan="2">..</td>
                    <td rowspan="2">1.00</td>
                    <td rowspan="2">1.00</td>
                    <td rowspan="2">0.75</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iicOnlineLevel1or2online : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iicOnlineLevel1or2offline : 0 }}</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iic_online_level_12 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iic_offline_level_12 : 0 }}</td>
                    <td rowspan="4">{{ (fourthStarScore) ? fourthStarScore?.iic_score : 0 }}</td>
                </tr>  
                <tr>
                  <!-- <td>Level 2</td> -->
                  <!-- <td>9</td> -->
                  <!-- <td>..</td> -->
                  <!-- <td>1.00</td>
                  <td>0.75</td> -->
              </tr>

                <tr  class="triicactivity">
                    <td rowspan="2">Level 3 & 4</td>
                    <td rowspan="2">15</td>
                    <td rowspan="2">25.00</td>
                    <td rowspan="2">..</td>
                    <td rowspan="2">1.67</td>
                    <td rowspan="2">1.67</td>
                    <td rowspan="2">1.25</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iicOnlineLevel3or4online : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iicOnlineLevel3or4offline : 0 }}</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iic_online_level_34 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iic_offline_level_34 : 0 }}</td>
                </tr>   
                <tr>
                  <!-- <td>Level 2</td> -->
                  <!-- <td>9</td> -->
                  <!-- <td>..</td> -->
                  <!-- <td>1.00</td>
                  <td>0.75</td> -->
              </tr>             
                <tr  class="trmicactivity">
                    <td>2</td>
                    <td>MIC Driven Activities</td>
                    <td></td>
                    <td>7</td>
                    <td>20.00</td>
                    <td>..</td>
                    <td>2.86</td>
                    <td>2.86</td>
                    <td>2.15</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.micAllOnline : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.micAllOffline : 0 }}</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.mic_online_c : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.mic_offline_c : 0 }}</td>
                    <td>{{ (formData) ? formData?.mic_total_marks : 0 }}</td>
                </tr>
                <tr  class="trceleactivity">
                    <td rowspan="2">3</td>
                    <td rowspan="2">Celebration Activities</td>
                    <td>Level 1 & 2</td>
                    <td rowspan="2">16</td>
                    <td rowspan="2">10.00</td>
                    <td rowspan="2">8</td>
                    <td>0.94</td>
                    <td>0.94</td>
                    <td>0.71</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowCelCountAllOnlineLevel1or2 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowCelCountAllOfflineLevel1or2 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.cel_online_level_12 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.cel_offline_level_12 : 0 }}</td>
                    <td rowspan="2"><strong>Score : </strong> {{ (formData) ? formData?.newCelebrationScore : 0 }}  
                      <br/><br /><strong>Reward : </strong> {{ (formData) ? formData?.newCelebrationReward : 0 }} </td>
                </tr>
                <tr  class="trceleactivity" >
                    <td>Level 3 & 4</td>
                    <td>1.25</td>
                    <td>1.25</td>
                    <td>0.94</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowCelCountAllOnlineLevel3or4 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowCelCountAllOfflineLevel3or4 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.cel_online_level_34 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.cel_offline_level_34 : 0 }}</td>
                </tr>
                <tr class="trselfactivity">
                    <td rowspan="5">4</td>
                    <td rowspan="5">Self-Driven Activities</td>
                    <td rowspan="2">Level 1 & 2</td>
                    <td rowspan="5">..</td>
                    <td rowspan="5">30.00</td>
                    <td rowspan="5">24</td>
                    <td rowspan="5">1.25</td>
                    <td rowspan="2">0.94</td>
                    <td rowspan="2">0.70</td>
                    <td rowspan="2"><strong> Self Driven Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel1or2 : 0 }} 
                      <br/><br /><strong>Self Driven Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel1or2 : 0 }}
                    </td>
                    <td rowspan="2"><strong>Self Driven Online : </strong> {{ (formData) ? formData?.self_online_level_12 : 0 }}
                      <br/><br /><strong>Self Driven Offline : </strong> {{ (formData) ? formData?.self_offline_level_12 : 0 }}
                    </td>
                    <td rowspan="5"><strong>Score : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_total_with_ia_score : 0 }}  
                      <br/><br /><strong>Reward : </strong> {{ (formData) ? formData?.newSelfDrivenReward : 0 }} </td>
                </tr>  
                <tr>
                  <!-- <td>Level 2</td> -->
                  <!-- <td>9</td> -->
                  <!-- <td>..</td> -->
                  <!-- <td>1.00</td>
                  <td>0.75</td> -->
              </tr>              
                <tr class="trselfactivity">
                  <td>IA Activties (Level 1 & 2)</td>
                  <td>0.94</td>
                  <td>0.70</td>
                  <td><strong> IA Verified Online : </strong> {{ (formData) ? (formData?.ia_total_online_selfdriven) : 0 }}
                    <br/><br/><strong>IA Verified Offline : </strong> {{ (formData) ? (formData?.ia_total_offline_selfdriven) : 0 }}
                  </td>
                  <td>
                    <br/><br/> <strong> IA Approved Online : </strong> {{ (formData) ? (formData?.ia_total_online_selfdriven) : 0 }}
                    <br/><br/><strong>IA Approved Offline : </strong> {{ (formData) ? (formData?.ia_total_offline_selfdriven) : 0 }}
                  </td>
                </tr>
                <tr  class="trselfactivity">
                    <td rowspan="2">Level 3 & 4</td>
                    <td rowspan="2">1.25</td>
                    <td rowspan="2">0.94</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel3or4 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel3or4 : 0 }}</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.self_online_level_34 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.self_offline_level_34 : 0 }}</td>
                </tr>
                <tr>
                  <!-- <td>Level 4</td> -->
                  <!-- <td>1.88</td>
                  <td>2.19</td>
                  <td>1.64</td> -->
                  <!-- <td><strong>Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel3or4 : 0 }}  
                    <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel3or4 : 0 }}</td>
                  <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_online_level_3_count : 0 }}  
                    <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_offline_level_3_count : 0 }}</td> -->
              </tr>                
                <tr class="trgreen">
                  <td></td>
                  <td colspan="2">Grand Total</td>
                  <!-- <td></td> -->
                  <td></td>
                  <td>100.00</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ (formData) ? formData?.four_star_total : 0 }}</td>
              </tr>
            </tbody>
        </table>
          
        <table class="table table-hover">
          <tbody>
            <tr>
                <!-- <td><strong>B</strong></td> -->
                <td colspan="5" style="text-align: center;" ><strong>Table B: Score for the IIC's Participation in Various Programs</strong></td>
            </tr>
            <tr>
                <td ><strong>S. No.</strong></td>
                <td colspan="2" style="text-align: center;"><strong>Incentive Parameters</strong></td>
                <td ><strong>Weightage</strong></td>
                <td ><strong>Score Obtained</strong></td>
            </tr>
            <tr>
                <td>1.</td>
                <td>Redemption of Reward Points Earned by the IIC (Upto 200 points)</td>
                <td >Institute can redeem the reward points earned by conducting over and above the prescribed limits of I&E Activities during the AY </td>
                <td >3%</td>
                <td ><span *ngIf="fstarScore?.reward_score">{{fstarScore?.reward_score}}</span>
                  <span *ngIf="!fstarScore?.reward_score">0</span></td>
            </tr>
            <tr>
                <td rowspan="4">2.</td>
                <td rowspan="4">Creation and Building a Functional YUKTI - Innovation & IP Repository at the institute level (Status as of 12th October 2024).&nbsp;</td>
                <td>I.&nbsp; Scouted and Verified a minimum of 30 Nos Ideas or PoCs during the AY.</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.idea_recommended_score">{{fstarScore?.idea_recommended_score}}</span>
                  <span *ngIf="!fstarScore?.idea_recommended_score">0</span>
                </td>
            </tr>
            <tr>
              <td>II.&nbsp; Scouted and Verified a Minimum of 15 Nos. nos of Innovations/ Prototypes during the AY</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.innovations_recommended_score">{{fstarScore?.innovations_recommended_score}}</span>
                  <span *ngIf="!fstarScore?.innovations_recommended_score">0</span>
                </td>
            </tr>
            <tr>
              <td>III.&nbsp; Scouted and Verified a Minimum of 5 Nos. SMEs having Udyami registration/ registered Startups/ DPIIT Recognised Startups)</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.startup_dpiit_registered_score">{{fstarScore?.startup_dpiit_registered_score}}</span>
                  <span *ngIf="!fstarScore?.startup_dpiit_registered_score">0</span>
                </td>
            </tr>
            <tr>
              <td>IV.&nbsp; Scouted and verified a minimum of 10 IP /Patents filed during the AY/institute has registered and received reimbursement of IP filing etc. with KAPILA</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.reimbursement_of_ip_filling">{{fstarScore?.reimbursement_of_ip_filling}}</span>
                  <span *ngIf="!fstarScore?.reimbursement_of_ip_filling">0</span>
                </td>
            </tr>
            <tr>
                <td rowspan="3">3.</td>
                <td rowspan="3">Innovations Participation from Institutions in YUKTI Innovation Challenge 2023, SIH 2024, Grand  Challenges Organised at MIC</td>
                <td>I. Institutions Nominated Innovations participation and Demonstrated and Pitched during the IIC Regional Meets.</td>
                <td>5%</td>
                <td ><span *ngIf="fstarScore?.innovation_participation_pitched_score">{{fstarScore?.innovation_participation_pitched_score}}</span>
                  <span *ngIf="!fstarScore?.innovation_participation_pitched_score">0</span>
                </td>
            </tr>
            <tr>
              <td>II.  Institutions Nominated Ideas participation in SIH2024 (3% for 50 ideas submission and 2% for finale)</td>
              <td >5%</td>
              <td ><span *ngIf="fstarScore?.yukti_idea_selected_participated_in_SIH_score">{{fstarScore?.yukti_idea_selected_participated_in_SIH_score}}</span>
                <span *ngIf="!fstarScore?.yukti_idea_selected_participated_in_SIH_score">0</span>
              </td>
            </tr>
            <tr>
              <td>III. Innovations from Institutions received funding assistance from MIC/Winners of the YUKTI Innovation Challenge/Grand Challenge 2024</td>
              <td >5%</td>
              <td ><span *ngIf="fstarScore?.fund_received_SIH_score">{{fstarScore?.fund_received_SIH_score}}</span>
                <span *ngIf="!fstarScore?.fund_received_SIH_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="3">4.</td>
              <td rowspan="3">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>The institute has referred/added at least 3 new institutions to the IIC network.&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referred_iic_score">{{fstarScore?.referred_iic_score}}</span>
                <span *ngIf="!fstarScore?.referred_iic_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The Institute has converted at least 2 inactive IIC institutes to active (enabling the institute to submit reports successfully in IIC6.0)&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.converted_inactive_to_active_iic_score">{{fstarScore?.converted_inactive_to_active_iic_score}}</span>
                <span *ngIf="!fstarScore?.converted_inactive_to_active_iic_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute has activated and managed well the sections such as "Constitute IIC", and "Consortium IIC" (in case of Affiliated and standalone institutions) or "Affiliated IIC" in case of affiliating University. &nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.constitute_iic_activated_score">{{fstarScore?.constitute_iic_activated_score}}</span>
                <span *ngIf="!fstarScore?.constitute_iic_activated_score">0</span>
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td colspan="2">The institute has Organised an Impact Lecture as a Mentee institute/ Delivered Mentorship Support to Mentee institutions as a mentor Institute&nbsp;</td>
              <td >8%</td>
              <td ><span *ngIf="fstarScore?.mm_report_submission">{{fstarScore?.mm_report_submission}}</span>
                <span *ngIf="!fstarScore?.mm_report_submission">0</span>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td colspan="2">The institute has hosted any one of the mega events of AICTE/MIC such as the IIC regional meet, SIH, and IDE Bootcamp for HEI/Schools.&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.institute_host_all_score">{{fstarScore?.institute_host_all_score}}</span>
                <span *ngIf="!fstarScore?.institute_host_all_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">7.</td>
              <td rowspan="2">Institute has adopted National Innovation and Startup Policy (NISP) at the institute Level.&nbsp;</td>
              <td>I. Institute has formulated an Institute Level Innovation & Entrepreneurship/IP/Incubation Policy (3%)&nbsp;</td>
              <td rowspan="2">6%</td>
              <td ><span *ngIf="fstarScore?.incubation_policy_score">{{fstarScore?.incubation_policy_score }}</span>
                <span *ngIf="!fstarScore?.incubation_policy_score">0</span>
              </td>
              
            </tr>
            <tr>
              <td>II. Implemention of Institute's I&E Policy (3%)&nbsp;</td>
              <td ><span *ngIf="fstarScore?.implementation_I_and_E_Policy_score">{{fstarScore?.implementation_I_and_E_Policy_score }}</span>
                <span *ngIf="!fstarScore?.implementation_I_and_E_Policy_score">0</span>
              </td>
            </tr>
            <tr>
              <td>8.</td>
              <td colspan="2">The institute has offered at least one Innovation and Entrepreneurship (I&E) course either in an academic format or in a non-academic format.&nbsp;</td>
              <td >4%</td>
              <td ><span *ngIf="fstarScore?.academic_course_offered_score">{{fstarScore?.academic_course_offered_score}}</span>
                <span *ngIf="!fstarScore?.academic_course_offered_score">0</span>
              </td>
            </tr>
            <tr>
              <td>9.</td>
              <td colspan="2">The institute has collaborated with I&E Ecosystem Enablers to promote and support I&E Agenda in the institute&nbsp;</td>
              <td >4%</td>
              <td ><span *ngIf="fstarScore?.colloboration_with_ecosystem_enablers_score">{{fstarScore?.colloboration_with_ecosystem_enablers_score}}</span>
                <span *ngIf="!fstarScore?.colloboration_with_ecosystem_enablers_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">10.</td>
              <td rowspan="2">The institute has a dedicated Infrastructure/Facility for Innovation and Entrepreneurship Support.&nbsp;</td>
              <td>The institute has full full-fledged functional Pre-Incubation and IPR/TTO facility in the Campus.&nbsp;</td>
              <td >4%</td>
              <td ><span *ngIf="fstarScore?.pre_incubation_and_ipr_total_score">{{fstarScore?.pre_incubation_and_ipr_total_score}}</span>
                <span *ngIf="!fstarScore?.pre_incubation_and_ipr_total_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute has full full-fledged functional Incubation facility in the Campus.&nbsp;</td>
              <td >6%</td>
              <td ><span *ngIf="fstarScore?.incubation_facility_score">{{fstarScore?.incubation_facility_score}}</span>
                <span *ngIf="!fstarScore?.incubation_facility_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="4">11.</td>
              <td rowspan="4">The institute has deployed trained Faculty Innovation Ambassadors to drive I&E ecosystem on campuses and IIC Activities. &nbsp;</td>
              <td>The Institute has nominated a minimum of 20 faculty members of the IIC to under go Innovation Ambassador (IA) Training.&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.ia_training_score">{{fstarScore?.ia_training_score}}</span>
                <span *ngIf="!fstarScore?.ia_training_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute has a minimum of 10 Nos of trained Faculty Innovation Ambassadors with completed Basic and Advanced Training Online.&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.ia_total_score">{{fstarScore?.ia_total_score}}</span>
                <span *ngIf="!fstarScore?.ia_total_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute has a minimum of 5 Nos of trained Faculty Innovation Ambassadors with Status of completed ReSkilling and Upskilling Certificate. &nbsp;</td>
              <td >3%</td>
              <td >
                <span *ngIf="iaUR">{{iaUR}}</span>
                <span *ngIf="!iaUR">0</span>
              </td>
            </tr>
            <tr> 
              <td>The institute has a minimum of 10 numbers of Active Faculty Innovation Ambassadors Submitted Experted Talks Reports&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.ia_report_submitted_score">{{fstarScore?.ia_report_submitted_score}}</span>
                <span *ngIf="!fstarScore?.ia_report_submitted_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">12.</td>
              <td rowspan="2">Institute has Mentoring Linkage Support to Atal Tinkering Lab (ATL) and School Innovation Council (SIC) in Schools&nbsp;</td>
              <td>The institute has mapped and linked with up to 3 ATLs/SICs for Mentoring Support&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.mapped_with_atl_sic_score">{{fstarScore?.mapped_with_atl_sic_score}}</span>
                <span *ngIf="!fstarScore?.mapped_with_atl_sic_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute has Conducted a minimum of 15 Mentoring Activities with ATLs/SICs &nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.activites_with_atl_sic_score">{{fstarScore?.activites_with_atl_sic_score}}</span>
                <span *ngIf="!fstarScore?.activites_with_atl_sic_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">13.</td>
              <td rowspan="2">Institute with Active IP Commercialisation & Technology Transfer Efforts&nbsp;</td>
              <td>The Institute with Inventive or innovative technologies ready for Commercialization or transfer&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.tt_ready_score">{{fstarScore?.tt_ready_score}}</span>
                <span *ngIf="!fstarScore?.tt_ready_score">0</span>
              </td>
            </tr>
            <tr>
              <td>The institute with Inventive or innovative technologies already Commercialized or transferred to industry or startup&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.tt_transfered_score">{{fstarScore?.tt_transfered_score}}</span>
                <span *ngIf="!fstarScore?.tt_transfered_score">0</span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Total &nbsp;</td>
              <td>&nbsp;</td>
              <td>100%</td>
              <td>{{fstarScore?.total_score}}</td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-sm-8 offset-sm-2">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Table C: Score Range and Star Allocation</span>

          <table style="text-align: center;" class="table table-hover">
            <tbody>
              <tr  style="background-color: #ececec !important;">
                <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Score Range</th>
                <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Star allocation</th>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<=20 </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">One star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 20<Score<=40
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Two star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 40<Score<=60
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Three Star </td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 60<Score<=75
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star  </td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 75<Score<=90
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 90<Score<=95
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Four and a Half Star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 95<Score<=100
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Five Star</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    </div>
  </div>
</div>