<div class="container">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Academic Courses">

      <div class="row" color="primary">
        <div class="col-md-12">
          <h2 class="ss headTitle">
            Academic Courses
          </h2>
        </div>
      </div>

      <p *ngIf="ForWhom=='Institute'"><a class="btn btn-info" href="/institute/teaching-learning-add/Add">Add New</a></p>
      
      <div class="tableBody">
				<div class="table-responsive">
          <table class="table table-hover">
            <thead class="facultyTable">
                <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Title</th>
                    <th scope="col">Course code</th>
                    <th scope="col">Academic Year</th>
                    <th scope="col">Type of Course</th>
                    <th scope="col">Level of The Course</th>
                    <th scope="col">Course offering department/Centre/Unit Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Document</th>
                    <th scope="col" *ngIf="ForWhom=='Institute'">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let nomination of academic; index as i" >
                    <td>{{i+1}}</td>
                    <td>{{nomination?.title}}</td>
                    <td>{{nomination?.course_code}}</td>
                    <td>{{nomination?.academic_year}}</td>
                    <td>{{nomination?.course_type}}</td>
                    <td>{{nomination?.course_level}}</td>
                    <td>{{nomination?.offering_department}}</td>
                    <td>{{ (nomination?.v_status =='Approved') ? 'Approved' : (nomination?.v_status =='Disapproved') ? 'Disapproved' : 'Not Reviewed' }}</td>
                    <td>
                      <button class="btn btn-info viewButton" *ngIf="nomination?.document_file">
                        <a href="{{ baseUrl + nomination?.document_file }}" target="_blank">View File</a>
                      </button>          
                    </td>
                    <td *ngIf="ForWhom=='Institute'">
                      <p><button *ngIf="nomination?.academic_year == '2024-25'" mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit','Academic')"   color="warn" class="btn-tbl-edit">
                        <mat-icon>edit</mat-icon>
                        </button></p>
                      <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete','Academic')"   color="warn" class="btn-tbl-delete">
                        <mat-icon>delete</mat-icon>
                        </button>
                    </td>                   
                </tr>
                <tr *ngIf="academic.length== 0" col="7">
                    <h3 style="text-align: center">No Data Found</h3>
                </tr>
            </tbody>
        </table>
        </div>
        </div>

    </mat-tab>
    <mat-tab label="Non-Academic Courses">
      <div class="row" color="primary">
        <div class="col-md-12">
          <h2 class="ss headTitle">
            Non-Academic Courses
          </h2>
        </div>
        
      </div>

      <p><a class="btn btn-info" href="/institute/teaching-nonacademic-add/Add"  *ngIf="ForWhom=='Institute'">Add New</a></p>

      <div class="tableBody">
				<div class="table-responsive">
          <table class="table table-hover">
            <thead class="facultyTable">
                <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Title</th>
                    <th scope="col">Certificate Program</th>
                    <th scope="col">Academic Year</th>
                    <th scope="col">No of Participants</th>
                    <th scope="col">Supported by</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Course offering department/Centre/Unit Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Document</th>
                    <th scope="col" *ngIf="ForWhom=='Institute'">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let nomination of nonacademic; index as i" >
                    <td>{{i+1}}</td>
                    <td>{{nomination?.title}}</td>
                    <td>{{nomination?.certificate_program}}</td>
                    <td>{{nomination?.academic_year}}</td>
                    <td>{{nomination?.no_of_participants}}</td>
                    <td>{{nomination?.supported_by}}</td>
                    <td>{{nomination?.start_date}}</td>
                    <td>{{nomination?.offering_department}}</td>
                    <td>{{ (nomination?.v_status =='Approved') ? 'Approved' : (nomination?.v_status =='Disapproved') ? 'Disapproved' : 'Not Reviewed' }}</td>
                    <td>
                      <button class="btn btn-info viewButton" *ngIf="nomination?.document_file">
                        <a href="{{ baseUrl + nomination?.document_file }}" target="_blank">View File</a>
                      </button>          
                    </td>
                    <td *ngIf="ForWhom=='Institute'">
                      <p>
                        <button *ngIf="nomination?.academic_year == '2024-25'" mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit','Nonacademic')"   color="warn" class="btn-tbl-edit editButton">
                        <mat-icon>edit</mat-icon>
                        </button>
                       
                      </p>
                       <p>
                        <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete','Nonacademic')"   color="warn" class="btn-tbl-delete deleteButton">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </p>
                    </td>                   
                </tr>
                <tr *ngIf="nonacademic.length== 0" col="7">
                    <h3 style="text-align: center">No Data Found</h3>
                </tr>
            </tbody>
        </table>
        </div>
        </div>
    </mat-tab>
  </mat-tab-group>
</div> 