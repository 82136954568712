import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class InstituteInnovationContestService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getIdea21Submission(pageNo, per_page = 10,ay = '2023-24') {
    return this.http.get(
      `${this.baseUrl}/get-ideas?per_page=` + per_page + `&page=` + pageNo + `&ay=` + ay
    );
  }

  getTeaRegistrationSubmission(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/get-registration-details?per_page=` + per_page + `&page=` + pageNo
    );
  }

  getIdeaSubmission(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/ideaSubmission?per_page=` + per_page + `&page=` + pageNo
    );
  }
  getIdeaSubmission21Filter(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/get-ideas-filter?per_page=10&page=` + pageNo + `&status=` + status
    );
  }

  getIdeaSubmissionFilter(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/ideaSubmission?per_page=10&page=` + pageNo + `&status=` + status
    );
  }

  getPocSubmissionFilter(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/pocSubmission?per_page=10&page=` + pageNo + `&status=` + status
    );
  }
  getPocSubmissionFilter21(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/get-poc-filter?per_page=10&page=` + pageNo + `&status=` + status
    );
  }
  getPrototypeSubmissionFilter(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/prototypeSubmission?per_page=10&page=` + pageNo + `&status=` + status
    );
  }
  getPrototypeSubmissionFilter21(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/get-proto-filter?per_page=10&page=` + pageNo + `&status=` + status
    );
  }


  submitIdeaSubmission(data) {
    return this.http.post(`${this.baseUrl}/verifyInnovationContest`, data);
  }

  submitIdeaSubmission21(data) {
    return this.http.post(`${this.baseUrl}/update-idea`, data);
  }

  submitYuktiSubmissionData21(data) {
    return this.http.get(`${this.baseUrl}/yukti-data?rid=`+data);
  }

  getPocSubmission(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/pocSubmission?per_page=` + per_page + `&page=` + pageNo
    );
  }
  getPocSubmission21(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/get-poc?per_page=` + per_page + `&page=` + pageNo
    );
  }

  submitPocSubmission(data) {
    return this.http.post(`${this.baseUrl}/verifyInnovationContest`, data);
  }
  submitPocSubmission21(data) {
    return this.http.post(`${this.baseUrl}/update-poc`, data);
  }
  getPrototypeSubmission(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/prototypeSubmission?per_page=` + per_page + `&page=` + pageNo
    );
  }
  getPrototypeSubmission21(pageNo, per_page = 10, type, ay='2023-24') {
    return this.http.get(
      `${this.baseUrl}/get-proto?per_page=` + per_page + `&page=` + pageNo+ `&type=` + type + `&ay=` + ay
    );
  }

  getStatusCounts(type) {
    return this.http.get(
      `${this.baseUrl}/innovationContest-status-count?type=` + type
    );
  }
  getStatusCounts21(type) {
    return this.http.get(
      `${this.baseUrl}/get-count?type=` + type
    );
  }
  submitPrototypeSubmission(data) {
    return this.http.post(`${this.baseUrl}/verifyInnovationContest`, data);
  }
  submitPrototypeSubmission21(data) {
    return this.http.post(`${this.baseUrl}/update-proto`, data);
  }
  getPrototypeDetails(pageNo) {
    return this.http.get(
      `${this.baseUrl}/prototype-verification/details?id=` + pageNo
    );
  }
  getPrototype21Details(pageNo) {
    return this.http.get(
      `${this.baseUrl}/prototype-verification21/details?id=` + pageNo
    );
  }
  getMasterDetails(type) {
    const data = {
      'type': type,
    };
    return this.http.post(`${this.baseUrl}/get-master-type`, data);
  }

  getQuarterDetails() {
    return this.http.get(`${this.baseUrl}/getQuarterDetails`);
  };

  // commonGetApi(url) {
  //   return this.http.get(
  //     `${this.baseUrl}${url}`
  //   );
  // };

  iaSessionList() {
    return this.http.get(`${this.baseUrl}/iaSessionList`);
  };

  getFacultyMember() {
    return this.http.get(`${this.baseUrl}/activity/facultyMember`);
  };

  getStudentMember() {
    return this.http.get(`${this.baseUrl}/activity/studentMember`);
  }

  getMonthlyReportData(event_id) {
    return this.http.get(`${this.baseUrl}/activity/monthlyReport/` + event_id);
  }

  monthlyReportSubmit(data) {
    return this.http.post(`${this.baseUrl}/activity/monthly_report`, data);
  }

  SelfmonthlyReportSubmit(data) {
    return this.http.post(`${this.baseUrl}/activity/selfdriven-activity-submission`, data);
  }

  selfDrivenAnnouncement(data) {
    return this.http.post(`${this.baseUrl}/activity/selfdrivenAnnouncement`, data);
  }

  updateSelfDrivenActivity(data, id) {
    return this.http.post(`${this.baseUrl}/activity/selfdrivenAnnouncementUpdate/` + id, data);
  }

  getNomination(pageNo, per_page = 10) {
    return this.http.get(
      `${this.baseUrl}/prototype-approved-nomination?per_page=` + per_page + `&page=` + pageNo
    );
  }

  getNominationCount() {
    return this.http.get(
      `${this.baseUrl}/prototype-nominated-count`);
  }

  getNominatedPrototype() {
    return this.http.get(
      `${this.baseUrl}/prototype-nominated?per_page=10&page=1`);
  }

  submitPrototype(prototype) {
    const data = {
      'check_list': prototype
    }
    return this.http.post(`${this.baseUrl}/prototype-nomination-submit`, data);
  }

  withdrawPrototype(id) {
    const data = {
      'id': id
    }
    return this.http.post(`${this.baseUrl}/prototype-nomination-withdraw`, data);
  }

  // Mentoring Session
  getMentoringSession() {
    return this.http.get(
      `${this.baseUrl}/getMentoringSession`);
  }

  getStageEvaluation() {
    return this.http.get(
      `${this.baseUrl}/stageOneEval`);
  }
  getStage2Evaluation() {
    return this.http.get(
      `${this.baseUrl}/stageTwoEval`);
  }

  getStage3Evaluation() {
    return this.http.get(
      `${this.baseUrl}/stageThreeEval`);
  }
  // Nomination schemes
  getBasicFaculties() {
    return this.http.get(
      `${this.baseUrl}/basicfaculties`);
  }
  getBasicStudents() {
    return this.http.get(
      `${this.baseUrl}/basicstudent`);
  }
  getAdvancedFaculties(){
    return this.http.get(
      `${this.baseUrl}/advanced`);
  }


  SubmitBasicfaculty(data){
    return this.http.post(
      `${this.baseUrl}/nominatefaculty`,data);
  }
  SubmitBasicstudent(data){
    return this.http.post(
      `${this.baseUrl}/nominatestudent`,data);
  }


  SubmitAdvancefaculty(data){
    return this.http.post(
      `${this.baseUrl}/nominateadvanced`,data);
  }

  DeleteFaculty(data,type){
    const data1={
      delete_id:data,
      type_id:type
    }
    return this.http.post(
      `${this.baseUrl}/removenominee`,data1);
  }

  EditFaculty(data,type) {
    const data1={
      //edit_id:data,
      type_id:type
    }
    return this.http.post(`${this.baseUrl}/editnominee` + data,data1);
  }

  getNominateCount(){
    return this.http.get(
      `${this.baseUrl}/getNominationCount2`);
  }

  submitYuktiUserList(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-yukti-user-verification`, data);
  }

  getVerificationDetails(proto_id){
    return this.http.get(`${this.baseUrl}/getVerifierData?proto_id=`+proto_id);
  }

  submitIicCoordinator(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-coordinator`, data);
  }

  submitIicEvaluator(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-evaluator`, data);
  }

  getEvaluatorsList(data) {
    return this.http.get(`${this.baseUrl}/get-iic-evaluator-assign?proto_id=`+data);
  }

  getDeleteIdeaFromVerification(data) {
    return this.http.get(`${this.baseUrl}/delete-idea-from-verification?proto_id=`+data);
  }

  DeleteProtoFromVerification(data, data2) {
    return this.http.get(`${this.baseUrl}/delete-proto-from-verification?proto_id=`+data+`&eval_id=`+data2);
  }

  submitEvaluatorsList(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-evaluator-assign`, data);
  }

  getYuktiInnovationData(data) {
    return this.http.get(`${this.baseUrl}/getInnovationDetails?proto_id=`+data);
  }

  getNominatedIA(){
    return this.http.get(
      `${this.baseUrl}/getNominatedIA`);
  }

  // getImpactTable(){
  //   return this.http.get(
  //     `${this.baseUrl}/getsessions`);
  // }

  getThemes(){
    return this.http.get(
      `${this.baseUrl}/themes`);
  }

  getHeads(){
    return this.http.get(
      `${this.baseUrl}/heads`);
  }


  submitImpactLectures(data){
    return this.http.post(`${this.baseUrl}/add-session`,data);
  }

  submitSpeakerDetails(data){
    return this.http.post(`${this.baseUrl}/submitSpeakerDetails`,data);
  }

  submitSessionDetails(data){
    return this.http.post(`${this.baseUrl}/submitSessionDetails`,data);

  }

  updateImapactSession(data){
    return this.http.post(
      `${this.baseUrl}/updateImapactSession`,data);
  }

  DeleteSession(data){
    const data1={
      id:data
    }
    return this.http.post(
      `${this.baseUrl}/removeImpactSession`,data1);
  }

  deleteSpeakerDetails(){
    return this.http.get(
      `${this.baseUrl}/deleteSpeakerDetails`);
  }

  getcheckSessionDetails(){
    return this.http.get(
      `${this.baseUrl}/checkSessionDetails`);
  }

  getsessions(){
    return this.http.get(`${this.baseUrl}/getsessions`);

  }

  getSpeakerDetails(){
    return this.http.get(`${this.baseUrl}/getSpeakerDetails`);
  }

  checkImpactEligibility(){
    return this.http.get(`${this.baseUrl}/check`);
  }

  checkNew(){
    return this.http.get(`${this.baseUrl}/checkNew`);
  }

  getIARegStatus(){
    return this.http.get(`${this.baseUrl}/getIARegStatus`);

  }

  getNominatedIAUpdated(data){
    return this.http.post(`${this.baseUrl}/getNominatedIAUpdated`,{type:data});
//  if(ia_id=='')
//   {
//     return this.http.post(`${this.baseUrl}/getNominatedIAUpdated`,{type:data});
//  }else{
//   return this.http.post(`${this.baseUrl}/getNominatedIAUpdated`,{type:data,ia_id});
//  }
    

  }



  getStudentNominatedIAUpdated(data){
    return this.http.post(`${this.baseUrl}/getNominatedIAUpdated2`,{type:data});
  }

  submitSessionReportIL(data) {
    return this.http.post(`${this.baseUrl}/submitSessionReportIL`, data);
  }

  getSessionReportIL(session_id, lecture_id) {
    return this.http.get(`${this.baseUrl}/getSessionReportIL?s_id=`+session_id+`&lecture_id=` + lecture_id);
  }

  submitSoeData(data) {
    return this.http.post(`${this.baseUrl}/submitSoeData`, data);
  }

  getSoeData() {
    return this.http.get(`${this.baseUrl}/getSoeData`);
  }

  getIaActivityProgress(type) {
    return this.http.get(`${this.baseUrl}/getIaActivityProgress?type=`+type);
  }

  submitIaActivityProgressStatus(data){
    return this.http.post(
      `${this.baseUrl}/submitIaActivityProgressStatus`,data);
  }

  challengeNominate(data){
    return this.http.post(
      `${this.baseUrl}/nominateYuktiChallenges`,data);
  }

  challengeNominateRemove(data){
    return this.http.post(
      `${this.baseUrl}/nominateYuktiChallengesRemove`,data);
  }
  getYuktiChallengeDetail(param=''){
    return this.http.get(`${this.baseUrl}/getYuktiChallengeDetail?allData=`+param);
  }

  yuktiChallengeNominateDetail(data){
    return this.http.post(`${this.baseUrl}/yuktiChallengeNominateDetail`,data);
  }

  yuktiChallengeNominateDelete(data){
    return this.http.get(`${this.baseUrl}/yuktiChallengeNominateDelete?id=`+data);
  }

  getAllInstitute(){
    return this.http.get(`${this.baseUrl}/getInstituteForNomination`);
  }

  getTransferInnovation(){
    return this.http.get(`${this.baseUrl}/get-transfer-innovation`);
  }
  removeTransferInnovation(id){
    return this.http.get(`${this.baseUrl}/remove-transfer-innovation?id=`+id);
  }
  transferInnovationReminder(id){
    return this.http.get(`${this.baseUrl}/transfer-for-innovation?id=`+id);
  }
  challengeRegionalMeetNominate(data){
    return this.http.post(
      `${this.baseUrl}/nominateYuktiChallenges-rm`,data);
  }

  challengeRegionalMeetNominateRemove(data){
    return this.http.post(
      `${this.baseUrl}/nominateYuktiChallengesRemove-rm`,data);
  }
  getRMCity(){
    return this.http.get(`${this.baseUrl}/get-rm-cities`);
  }
}
