import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-sic-verification',
  templateUrl: './sic-verification.component.html',
  styleUrls: ['./sic-verification.component.css']
})
export class SicVerificationComponent implements OnInit {

  captchaResponse: any;
    public form = {
      email: null,
      password: null,
    };
  
    public error = null;
    vid:any;
    message:any;
    status:any;
    sData:any;
  
    constructor(
      private jarwis: JarwisService,
      private route: ActivatedRoute
    ) {
  
  
  
    }
  
    ngOnInit()
    {
      this.vid = "";
  
      this.route.queryParams
        .subscribe(params => {
          this.vid = params.vid;
        }
      );
  
        this.jarwis.sicVerificationSend(this.vid).subscribe(
          (data) => this.handleResponse(data)
        )
  
    }
  
    handleResponse(data) {
      this.message = data['message'];
      this.status = data['status'];
      this.sData = data['InstituteData'];
    }

}
