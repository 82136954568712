import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InstituteEvaluatorAssignDialogComponent } from '../../institute-yic2021/institute-evaluator-assign-dialog/institute-evaluator-assign-dialog.component';
import { InstituteEvaluationDetailsComponent } from '../../institute-yic2021/institute-evaluation-details/institute-evaluation-details.component';
import { InstituteTeamLeadVerifyDialogComponent } from '../../institute-yic2021/institute-team-lead-verify-dialog/institute-team-lead-verify-dialog.component';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-institute-stage-i-evaluation',
  templateUrl: './institute-stage-i-evaluation.component.html',
  styleUrls: ['./institute-stage-i-evaluation.component.css']
})
export class InstituteStageIEvaluationComponent implements OnInit {

  baseUrl = myGlobals.baseUrl;

  public startUpNomantion : FormGroup;
  yukti_challenges: any;
  evaluation: any[];

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  prototypeSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  shotToken:any;
  ForWhom: any;
  selected = '2024-25';
  academicYear :any;

  constructor( private dialog: MatDialog, private innovationService: InstituteInnovationContestService, private toastr: ToastrService,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.academicYear = '2024-25';

    this.ForWhom=localStorage.getItem('ForWhom');
    this.yuktiChallenge();
    this.getPrototypeSubmission(this.academicYear);

    this.shotToken=localStorage.getItem('token');

    this.getPrototypeCount();

    this.startUpNomantion = this.fb.group({
      challenge_id: [null],
      innovation_id: [null],
    });
  }
  getPrototypeCount(){
    this.innovationService.getStatusCounts21('startup').subscribe(
      data => this.counts = data['startup']['0']
    )
  }
    getPrototypeSubmission(ay) {
      this.innovationService.getPrototypeSubmission21(this.currentPageNo,this.per_page, 'Startup',ay).subscribe(
        data => this.handleResponse(data)
      );
    }

    handleResponse(data) {

      this.prototypeSubmission = [];

      data['data'].forEach((element) => {
        this.prototypeSubmission.push(element);
      });

  this.total_pages = data.meta.pagination.total;
      if (
        data['meta']['pagination']['current_page'] ==
        data['meta']['pagination']['total_pages']
      ) {
        this.firstPage = false;
        this.lastPage = true;
      } else {
        this.lastPage = false;
      }

      if (data['meta']['pagination']['current_page'] == 1) {
        this.firstPage = true;
        this.lastPage = false;
      } else {
        this.firstPage = false;
      }
    }

    nextPage(e: any) {
      if(this.per_page != e.pageSize) {

        this.currentPageNo = (e.pageIndex+1);
      } else {
      this.currentPageNo = (e.pageIndex+1);
      }
    this.per_page = e.pageSize;
      if (this.filterModel == "All") {
        this.getPrototypeSubmission(this.academicYear);
      } else {
        this.changeFilter();
      }
    }

    previousPage() {
      this.currentPageNo--;
      if (this.filterModel == "All") {
        this.getPrototypeSubmission(this.academicYear);
      } else {
        this.changeFilter();
      }
    }

    openEvaluationViewDialog(data) {
      const dialogRef = this.dialog.open(InstituteEvaluationDetailsComponent, {
        data: data
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.getPrototypeSubmission(this.academicYear);
      });
    }

    changeFilter() {
      if (this.filterModel == "All") {
        this.getPrototypeSubmission(this.academicYear);
      } else {
        this.innovationService.getPrototypeSubmissionFilter21(this.currentPageNo, this.filterModel).subscribe(
          data => this.handleResponse(data)
        );
      }
    }

    openFileToDownload(id)
  {
    window.open(this.baseUrl+'/DownloadInnovationDetails?proto_id='+id+'&token='+this.shotToken);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  openEvaluationDialogTeamData(data) {
    const dialogRef = this.dialog.open(InstituteTeamLeadVerifyDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }


  openEvaluationDialog(data) {
    const dialogRef = this.dialog.open(InstituteEvaluatorAssignDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getPrototypeSubmission(this.academicYear);
    });
  }

  DeleteIdeaFromVerification(data, title) {
    if (confirm("Are you sure want to delete "+title) == true) {
      this.innovationService.getDeleteIdeaFromVerification(data).subscribe(
        data => this.handleResponseDelete(data)
      );
    }


  }

  handleResponseDelete(data) {

    if(data["status"]==1)
    {
      this.toastr.success(data["msg"]);
      this.getPrototypeSubmission(this.academicYear);
      this.getPrototypeCount();

    }
    else
    {
      this.toastr.error(data["msg"]);
    }

  }
  yuktiChallenge() {
    this.innovationService.getYuktiChallengeDetail().subscribe(
      data => this.handleChallengeResponse(data)
    );
  
}

handleChallengeResponse(data){
  this.yukti_challenges = data;
}
handleChallengeSubmitResponse(data){
  this.toastr.success('Nominate Sucessfully');
  // this.yukti_challenges = data;
}

onSubmit(value) {
  var formData = new FormData();

  formData.append('challenge_id', this.startUpNomantion.get('challenge_id').value);
  formData.append('innovation_id', this.startUpNomantion.get('innovation_id').value);
  this.innovationService.yuktiChallengeNominateDetail(formData).subscribe(
    data => this.handleChallengeSubmitResponse(data)
  );
}

onAYChange(event){
  let ay = event.value;
  this.academicYear = ay;
  this.getPrototypeSubmission(ay);
}

}
