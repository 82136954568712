<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
	.navbar-nav>li {
		float: none !important;
		text-align: center !important;
		line-height: 24px;
	}
</style>

<!-- New Login -->

<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<span class="login100-form-title loginInstiTopContent">
				<h2>MOE’s Innovation Cell</h2>
				<p>Institution’s Innovation Council</p>
				<span style="font-weight: 500;">SIC - Join for Mentoring</span>
			</span>
      <div *ngIf="status==0" class="alert alert-danger" style="width: 100%;">
        The verification code is invalid. Please contact you institute.
      </div>

      <div *ngIf="status==1" class="alert alert-success" style="width: 100%;">
        <p style="font-size: 20px; text-align:center;">You have been successfully joined the mentoring session. Your institute details is as follows:</p>
        <table class="table table-hover" style="margin-top: 20px !important; margin-bottom: 20px !important;">
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Institute Name
            </td>
            <td style="vertical-align: top;">{{sData.instituteName}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            IIC ID
            </td>
            <td style="vertical-align: top;">{{sData.userId}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Email ID
            </td>
            <td style="vertical-align: top;">{{sData.email}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Mobile
            </td>
            <td style="vertical-align: top;">{{sData.mobile}}</td>
          </tr>
        </table>
      </div>

      <div *ngIf="status==2" class="alert alert-primary" style="width: 100%;">
        <p style="font-size: 20px; text-align:center;">You have been already joined the mentoring session. Your institute details is as follows. Please contact him for further assistance.</p>
        <table class="table table-hover" style="margin-top: 20px !important; margin-bottom: 20px !important;">
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Institute Name
            </td>
            <td style="vertical-align: top;">{{sData.instituteName}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            IIC ID
            </td>
            <td style="vertical-align: top;">{{sData.userId}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Email ID
            </td>
            <td style="vertical-align: top;">{{sData.email}}</td>
          </tr>
          <tr>
            <td width="150" style="width: 150px; vertical-align: top;">
            Mobile
            </td>
            <td style="vertical-align: top;">{{sData.mobile}}</td>
          </tr>
        </table>
      </div>




		</div>
	</div>
</div>



<!-- Footer
<app-institute-footer></app-institute-footer>-->
