import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent implements OnInit {

  showEstablishment: boolean;
  showCertificate: number;
  showRating: boolean;
  showRating1920: boolean;
  showRating2021: boolean;
  showRating2022: boolean;
  showRating2023: boolean;
  showRating2024: boolean;

  constructor(private mycouncilService: InstituteMyCouncilService,
    private router: Router) { }

  ngOnInit(): void {
    this.mycouncilService.checkMyCouncil().subscribe(
      data => this.checkEstablishment(data)
    )
    this.mycouncilService.checkRatingStatus().subscribe(
      data => this.checkRatings(data)
    )

    this.mycouncilService.getInstituteRating1920().subscribe(
      data => this.checkRatings1920(data)
    )

    }

  openDialog() {
    this.router.navigateByUrl('/institute/memberCertificateList');
  }


  checkRatings(data) {
    if (data['rating_status'] == true) {
      this.showRating = true;
    } else {
      this.showRating = false;
    }
  }

  checkRatings1920(data) {
    if (data['rating_status'] == true) {
      this.showRating1920 = true;
    } else {
      this.showRating1920 = false;
    }

    if (data['rating_status202021'] == true) {
      this.showRating2021 = true;
    } else {
      this.showRating2021 = false;
    }

    if (data['rating_status202122'] == true) {
      this.showRating2022 = true;
    } else {
      this.showRating2022 = false;
    }
    if (data['rating_status202223'] == true) {
      this.showRating2023 = true;
    } else {
      this.showRating2023 = false;
    }
    if (data['rating_status202324'] == true) {
      this.showRating2024 = true;
    } else {
      this.showRating2024 = false;
    }


  }

  checkEstablishment(data) {
    if (data['applicationStatus'] == '1') {
      this.showEstablishment = true;
    }
    this.showCertificate = data['applicationStatusDisapproved'];

  }

  getEstablishmentCertificate() {
    this.mycouncilService.getEstablismentCertificate().subscribe(
      data => this.downloadFile(data, 'EstablismentCertificate.pdf')
    );
  }

  getEstablishmentCertificate2() {
    this.mycouncilService.getEstablismentCertificate2().subscribe(
      data => this.downloadFile(data, 'EstablismentCertificate.pdf')
    );
  }


  getRatingCertificate() {
    this.mycouncilService.getRatingCertificate().subscribe(
      data => this.downloadFile(data, 'RatingCertificate.pdf')
    );
  }

  getRatingCertificate1920() {

    this.mycouncilService.getRatingCertificate1920().subscribe(
      data => this.downloadFile(data, 'RatingCertificate_2019-20.pdf')
    );

  }

  getRatingCertificate2021(data){

    this.mycouncilService.getRatingCertificate2021(data).subscribe(
      data => this.downloadFile(data, 'RatingCertificate_2020-21.pdf')
    );

  }

  getRatingCertificate2022(data){

    this.mycouncilService.getRatingCertificate2021(data).subscribe(
      data => this.downloadFile(data, 'RatingCertificate_2021-22.pdf')
    );

  }

  getRatingCertificate2023(data){

    this.mycouncilService.getRatingCertificate2021(data).subscribe(
      data => this.downloadFile(data, 'RatingCertificate_2022-23.pdf')
    );

  }
  
  getRatingCertificate2024(data){

    this.mycouncilService.getRatingCertificate2021(data).subscribe(
      data => this.downloadFile(data, 'RatingCertificate_2023-24.pdf')
    );

  }

  getLetterofAppriciation(data){
    if(data == '2021-22'){
      this.mycouncilService.getLetterofAppriciation2122(data).subscribe(
        data => this.downloadFile(data, 'IIC_Appreciation_Letter_AY_2021_22.pdf')
      );
    }
    else if(data == '2022-23'){
      this.mycouncilService.getLetterofAppriciation2223(data,'members_22_23').subscribe(
        data => this.downloadFile(data, 'IIC_Appreciation_Letter_AY_2022_23.pdf')
      );
    }else if(data == '2023-24'){
      this.mycouncilService.getLetterofAppriciation2223(data,'members_23_24').subscribe(
        data => this.downloadFile(data, 'IIC_Appreciation_Letter_AY_2023_24.pdf')
      );
    }

  }


  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

}
