 <!--title-->
 <title>

    Contact Us | MHRD
    </title>
    <!--end of title--->

<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="page-title">Contact Us</h1>
                <div class="sq1"></div>
                <div class="sq2"></div>
                <div class="sq3"></div>
            </div>
        </div>
    </div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item"><a>About Us</a></li>
    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
  </ul>
</nav>
	

    <div class="container wh-padding">
        <div class="row innovationAmbassadorBox ambPadding1">
            <div class="col-md-12">
                <div class="headingAmbassador text-center">
                    <h2 class="pt-3">Contact Us</h2>
                </div>
            </div>
        </div>
    </div>


<section class="zonalCordinaterBox">
	<div class="container">
		<div class="row asd justify-content-center">
			<div class="col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/dipan-sahu.jpg" alt="Mr. Dipan Sahu"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Dipan Sahu</h5>
					<p class="impTeamFirstPara">Assistant Innovation Director</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
					<p class="text-center cardSmallFont" style="color:#c71e23;">
					  <b>	dipan.sahu@aicte-india.org <br>
							dipan.sahu@gov.in <br>
						011 2958 1226
					</b>
					</p>

				  </div>
				</div>
				<!-- <p class="mt-3 w-100 float-left text-center"><strong><h2 style="text-align: center; color: #000;">National Coordinator</h2></strong></p> -->
			</div>
		</div>
		<div class="row asd">

			
			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1">
											<img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani">
										</div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Southern/SRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>sro.iic.mic@aicte-india.org </b>
										</p>
										<h5 id="spacc1">South-Central/SCRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>scro.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Selvarani</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Innovation Officer <br>
										MoE's Innovation Cell <br>
										011 2958 1513
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
										<td height="40" rowspan="1" id="td4">
										   Southern/SRO
										</td>
										<td id="td2">
										   Tamil Nadu
										</td>
										<td id="td2">
										   Puducherry
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:sro.iic.mic@aicte-india.org">sro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1513">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div>
								
								<!-- <div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   North-West/NWRO
										</td>
										<td id="td2">
										   Chandigarh
										</td>
										<td id="td2">
										   Delhi
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Haryana
										</td>
										<td id="td2">
										   Himachal Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr>
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div> -->
							</div>
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
										<td height="40" rowspan="1" id="td4">
										   South-Central/SRO
										</td>
										<td id="td2">
											Telangana
										</td>
										<td id="td2">
											Andhra Pradesh
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1513">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div>
								
								<!-- <div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   North-West/NWRO
										</td>
										<td id="td2">
										   Chandigarh
										</td>
										<td id="td2">
										   Delhi
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Haryana
										</td>
										<td id="td2">
										   Himachal Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr>
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div> -->
							</div>
					   </div>
				</div>
			</div>
			
			<!-- <div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Northern/NRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nro.iic.mic@aicte-india.org </b>
										</p>
										<h5 id="spacc1">Eastern/ERO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>ero.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Udyan Maurya</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow <br>
										MoE's Innovation Cell <br>
										011 2958 1240
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>
									 <tr >
										<td height="60" rowspan="2" id="td4">
										   Northern/NRO
										</td>
										<td id="td2">
										   Bihar
										</td>
										<td id="td2">
										   Uttar Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Uttarakhand
										</td>
										<td id="td2">
										</td>
									 </tr>
									 <tr>
												<td colspan="3" style="padding:0">
												   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
												</td>
											 </tr>
											 <tr>
												<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1223">011 2958 1240</a>
												</td>
											 </tr>
								  </table>
								</div>
								<div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
											<td height="258" rowspan="6" id="td4">
											   Eastern/ERO
											</td>
											<td id="td2">
											   Andaman and Nicobar Islands
											</td>
											<td id="td2">
											   Arunachal Pradesh
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Assam
											</td>
											<td id="td2">
											   Jharkhand
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Manipur
											</td>
											<td id="td2">
											   Meghalaya
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Mizoram
											</td>
											<td id="td2">
											   Nagaland
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Odisha/Orissa
											</td>
											<td id="td2">
											   Sikkim
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Tripura
											</td>
											<td id="td2">
											   West Bengal
											</td>
										 </tr>
										<tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1240">011 2958 1240</a>
											</td>
										</tr>
								  </table>
								</div>
							</div>
					   </div>
				</div>
			</div> -->
			
			
			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">South-Western/SWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>swro.iic.mic@aicte-india.org </b>
										</p>
										<h5 id="spacc1">Central/CRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>cro.iic.mic@aicte-india.org
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Innovation Officer <br>
										MoE's Innovation Cell <br>
										011 2958 1517
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-6">
									<table>
									   <tr class="btn-primary-table">
									   <td id="td1">
										<b id="font1"> Zone </b>
									   </td>
									   <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									   </td>
									   
									   </tr>
									   <tr>
										<td id="td4">
										   South-West/SWRO
										</td>
										<td id="td2">
										   Karnataka
										</td>
										<td id="td2">
										   Kerala
										</td>
									   </tr>
									   <tr>
												<td colspan="3" style="padding:0">
													<a class="emailText" href="mailto:swro.iic.mic@aicte-india.org">swro.iic.mic@aicte-india.org</a>
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
												</td>
											</tr>
									  </table>
								</div>
								<div class="col-md-6">
									<table>
									   <tr class="btn-primary-table">
										   <td id="td1">
												<b id="font1"> Zone </b>
										   </td>
										   <td id="td1" colspan="3">
												<b id="font1"> State
											</b>
										   </td>
										   
										</tr>
										   <tr >
												<td rowspan="2" id="td4">
												   Central/CRO
												</td>
												<td id="td2">
												   Chhattisgarh
												</td>
												<td id="td2">
												   Gujarat
												</td>
											</tr>
											<tr>
												<td id="td2">
												   Madhya Pradesh
												</td>
												<td id="td2">
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													<a class="emailText" href="mailto:cro.iic.mic@aicte-india.org">cro.iic.mic@aicte-india.org</a>
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
												</td>
											</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div>
			
			


			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1">
											<img class="card-img-top" src="assets/assets/images/imp-team/Jerry.png" alt="Jerry">
										</div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">North-Western/NWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nwro.iic.mic@aicte-india.org</b>
										</p>
										<h5 id="spacc1">North/NWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nro.iic.mic@aicte-india.org</b>
										</p>
										
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Jerry Joshy</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow<br>
										MoE's Innovation Cell <br>
										011 2958 1225
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>



					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   North-West/NWRO
										</td>
										<td id="td2">
										   Chandigarh
										</td>
										<td id="td2">
										   Delhi
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Haryana
										</td>
										<td id="td2">
										   Himachal Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr>
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1225</a>
										</td>
									 </tr>
								  </table>
								</div>
								
							</div>
							<!-- start 13-1 -->
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   Northern/NRO
										</td>
										<td id="td2">
										   Bihar
										</td>
										<td id="td2">
										   Uttar Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Uttarakhand
										</td>
										
									 </tr>
									 <!-- <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr> -->
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1225</a>
										</td>
									 </tr>
								  </table>
								</div>
								
							</div>
							 <!-- end 13-1 -->
					   </div>
				</div>
			</div>

			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Monika.png" alt="Monika"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Western/WRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>wro.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Monika Choudhary</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Young Professional<br>
										MoE's Innovation Cell <br>
										011 2958 1227
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
						<div class="row">
							<div class="col-md-12">
								<table>
									 <tr class="btn-primary-table">
										 <td id="td1">
											<b id="font1"> Zone </b>
										 </td>
										 <td id="td1" colspan="3">
											<b id="font1"> State
											</b>
										 </td>
									 </tr>
									 
									 <tr>
										<td rowspan="2" id="td4">
										   Western/WRO
										</td>
										<td id="td2">
										   Goa
										</td>
										<td id="td2">
										   Maharashtra
										</td>
									 </tr>
									 <tr >
										<td id="td2">
										   Dadra and Nagar Haveli
										</td>
										<td id="td2">
										   Daman and Diu
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
											<a class="emailText" href="mailto:wro.iic.mic@aicte-india.org">wro.iic.mic@aicte-india.org</a>
										</td>
									</tr>
									<tr>
										<td colspan="3" style="padding:0">
											   <a class="phoneText" href="tel:011 2958 1235">011 2958 1336</a>
										</td>
									</tr>
								  </table>
							</div>
						</div>
				   </div>
				</div>
			</div>

			<div class="col-xl-6 col-md-6 mt-4 card-container">
				<div class="card-flip">
				   <div class="card front implementCardBox">
					  <div class="card profile-card-5 newImpBoxCard">
						 <div class="row">
							<div class="col-md-6 cardBdrRight">
								<div class="card-img-block">
									<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/subrat.jpg" alt="Mr. subrat"></div>
								 </div>
							</div>
							<div class="col-md-6">
								<div class="card-img-block1">
									<h5 id="spacc1">Eastern/ERO</h5>
									<p class="text-center cardSmallFont" style="color:#c71e23;">
									   <b>ero.iic.mic@aicte-india.org</b>
									</p>
									
								 </div>
							</div>
						 </div>
					  </div>
					  <div class="card profile-card-5">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body pt-0">
									<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Subrat Kr. Sahu</h5>
									<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow <br>
									MoE's Innovation Cell <br>
									011 2958 1235
									</p>	
								 </div>
							</div>
						</div>
					  </div>
				   </div>
				   <div class="card back">
					<div class="row">
						
						<div class="col-md-12">
							<table>
							 <tr class="btn-primary-table">
							 <td id="td1">
								<b id="font1"> Zone </b>
							 </td>
							 <td id="td1" colspan="3">
								<b id="font1"> State
								</b>
							 </td>
							 </tr>
							 <tr>
									<td height="258" rowspan="6" id="td4">
									   Eastern/ERO
									</td>
									<td id="td2">
									   Andaman and Nicobar Islands
									</td>
									<td id="td2">
									   Arunachal Pradesh
									</td>
								 </tr>
								 <tr >
									<td id="td2">
									   Assam
									</td>
									<td id="td2">
									   Jharkhand
									</td>
								 </tr>
								 <tr>
									<td id="td2">
									   Manipur
									</td>
									<td id="td2">
									   Meghalaya
									</td>
								 </tr>
								 <tr>
									<td id="td2">
									   Mizoram
									</td>
									<td id="td2">
									   Nagaland
									</td>
								 </tr>
								 <tr>
									<td id="td2">
									   Odisha/Orissa
									</td>
									<td id="td2">
									   Sikkim
									</td>
								 </tr>
								 <tr >
									<td id="td2">
									   Tripura
									</td>
									<td id="td2">
									   West Bengal
									</td>
								 </tr>
								<tr>
									<td colspan="3" style="padding:0">
										<a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
									</td>
								</tr>
								<tr>
									<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1240">011 2958 1227</a>
									</td>
								</tr>
						  </table>
						</div>
					</div>
			   </div>
			</div>
		</div>

			<!-- <div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Seema.png" alt="Seema"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">South-Central/SCRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>scro.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Seema Chhillar</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">DEO <br>
										MoE's Innovation Cell <br>
										011 2958 1227
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								
								<div class="col-md-12">
									<table>
										 <tr class="btn-primary-table">
											 <td id="td1">
												<b id="font1"> Zone </b>
											 </td>
											 <td id="td1" colspan="2">
												<b id="font1"> State
												</b>
											 </td>
										 </tr>
										 
										 <tr>
											<td>
											   South-Central/SCRO 
											</td>
											<td>
												Andhra Pradesh
											</td>
											<td>
											   Telangana
											</td>
										 </tr>
										
										 <tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1326">011 2958 1227</a>
											</td>
										</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div> -->
			<!-- <div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Pooja.png" alt="Pooja"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Northern/NRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nro.iic.mic@aicte-india.org </b>
										</p>
										
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Pooja Sharma</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">DEO <br>
										MoE's Innovation Cell <br>
										011 2958 1235
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>
									 <tr >
										<td height="60" rowspan="2" id="td4">
										   Northern/NRO
										</td>
										<td id="td2">
										   Bihar
										</td>
										<td id="td2">
										   Uttar Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Uttarakhand
										</td>
										<td id="td2">
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1235</a>
										</td>
									 </tr>
								  </table>
								</div>
								
							</div>
					   </div>
				</div>
			</div> -->

			
			
			
		</div>
	</div>
</section>


<!-- <section class="zonalCordinaterBox">
   <div class="container">
      <div class="row asd">
          <div class="col-xl-3 col-md-3 mt-4 card-container">
               <div class="card-flip">
                  <div class="card front implementCardBox">
                    <div class="card profile-card-5">
                      <div class="card-img-block">
                        <h5 id="spacc">Southern/SRO</h5>
                        <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani"></div>
                      </div>
                      <div class="card-body pt-0">
                        <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Ms. Selvarani</h5>
                        <p class="text-center cardSmallFont" style="color:#c71e23;">
                           <b>   sro.iic.mic@aicte-india.org <br>
                           011 2958 1513
                           </b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card back">
                      <table>
                      <tr class="btn-primary-table">
                      <td id="td1">
                        <b id="font1"> Zone </b>
                      </td>
                      <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                      </td>
                      </tr>
                      <tr>
                        <td height="40" rowspan="1" id="td4">
                           Southern/SRO
                        </td>
                        <td id="td2">
                           Tamil Nadu
                        </td>
                        <td id="td2">
                           Puducherry
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:0">
                           <a class="emailText" href="mailto:sro.iic.mic@aicte-india.org">sro.iic.mic@aicte-india.org</a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:0">
                           <a class="phoneText" href="tel:011 2958 1513">011 2958 1513</a>
                        </td>
                      </tr>
                    </table>
                  </div>
            </div>
         </div>

         
         <div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                  <div class="card profile-card-5">
                     <div class="card-img-block">
                  <h5 id="spacc2">Northern/NRO</h5>
                        <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
                     </div>
                     <div class="card-body pt-0">
                        <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Udyan Maurya</h5>
                        <p class="text-center cardSmallFont" style="color:#c71e23;">
                           <b>nro.iic.mic@aicte-india.org <br>
                          
                           011 2958 1240
                           </b>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="card back">
                  
                  <table>
                     <tr class="btn-primary-table">
                     <td id="td1">
                        <b id="font1"> Zone </b>
                     </td>
                     <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                     </td>
                     
                     </tr>
                     <tr >
                        <td height="60" rowspan="2" id="td4">
                           Northern/NRO
                        </td>
                        <td id="td2">
                           Bihar
                        </td>
                        <td id="td2">
                           Uttar Pradesh
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Uttarakhand
                        </td>
                        <td id="td2">
                        </td>
                     </tr>
                     <tr>
                        <td colspan="3" style="padding:0">
                           <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:0">
                           <a class="phoneText" href="tel:011 2958 1223">011 2958 1240</a>
                        </td>
                      </tr>
                  </table>
                
               </div>
            </div>
        </div>

      <div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                  
                  <div class="card profile-card-5">
                     <div class="card-img-block">
                  <h5 id="spacc2">North-West/NWRO</h5>
                        <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani"></div>
                     </div>
                     <div class="card-body pt-0">
                        <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Ms. Selvarani</h5>
                        <p class="text-center cardSmallFont" style="color:#c71e23;">
                           <b>nwro.iic.mic@aicte-india.org <br>
                          
                           011 2958 1513
                           </b>
                        </p>
                     </div>
                  </div>

               </div>
               <div class="card back">
                 
                  <table>
                     <tr class="btn-primary-table">
                     <td id="td1">
                        <b id="font1"> Zone </b>
                     </td>
                     <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                     </td>
                     
                     </tr>

                     <tr>
                        <td height="140" rowspan="4" id="td4">
                           North-West/NWRO
                        </td>
                        <td id="td2">
                           Chandigarh
                        </td>
                        <td id="td2">
                           Delhi
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Haryana
                        </td>
                        <td id="td2">
                           Himachal Pradesh
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Jammu and Kashmir
                        </td>
                        <td id="td2">
                           Punjab
                        </td>
                     </tr>
                     <tr>
                        <td  id="td2">
                           Rajasthan
                        </td>
                        <td  id="td2">
                        </td>
                     </tr>
                     <tr>
                     <td colspan="3" style="padding:0">
                           <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:0">
                           <a class="phoneText" href="tel:011 2958 1223">011 2958 1513</a>
                        </td>
                      </tr>
                  </table>
                  
               </div>
            </div>
        </div>


        <div class="col-md-3 mt-4 card-container">
          <div class="card-flip">
             <div class="card front implementCardBox">
              <div class="card profile-card-5">
               <div class="card-img-block">
            <h5 id="spacc2">South-Western/SWRO</h5>
                <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
               </div>
               <div class="card-body pt-0">
                <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
                <p class="text-center cardSmallFont" style="color:#c71e23;">
                   <b>  swro.iic.mic@aicte-india.org <br>
                   
                   011 2958 1517
                   </b>
                </p>
               </div>
              </div>

             </div>
             <div class="card back">
              
              <table>
               <tr class="btn-primary-table">
               <td id="td1">
                <b id="font1"> Zone </b>
               </td>
               <td id="td1" colspan="3">
                <b id="font1"> State
                </b>
               </td>
               
               </tr>
               <tr>
                <td id="td4">
                   South-West/SWRO
                </td>
                <td id="td2">
                   Karnataka
                </td>
                <td id="td2">
                   Kerala
                </td>
               </tr>
               <tr>
                  <td colspan="3" style="padding:0">
                     <a class="emailText" href="mailto:swro.iic.mic@aicte-india.org">swro.iic.mic@aicte-india.org</a>
                  </td>
               </tr>
               <tr>
                  <td colspan="3" style="padding:0">
                        <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
                  </td>
               </tr>
              </table>
              
             </div>
          </div>
        </div>



         <div class="col-md-3 mt-4 card-container">
          <div class="card-flip">
             <div class="card front implementCardBox">
             
              <div class="card profile-card-5">
               <div class="card-img-block">
            <h5 id="spacc2">Central/CRO</h5>
                <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
               </div>
               <div class="card-body pt-0">
                <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
                <p class="text-center cardSmallFont" style="color:#c71e23;">
                   <b>  cro.iic.mic@aicte-india.org <br>
                  
                   011 2958 1517
                   </b>
                </p>
               </div>
              </div>

             </div>
             <div class="card back">
             
              <table>
               <tr class="btn-primary-table">
                  <td id="td1">
                     <b id="font1"> Zone </b>
                  </td>
                  <td id="td1" colspan="3">
                     <b id="font1"> State
                   </b>
                  </td>
                  
               </tr>
               <tr >
                  <td rowspan="2" id="td4">
                     Central/CRO
                  </td>
                  <td id="td2">
                     Chhattisgarh
                  </td>
                  <td id="td2">
                     Gujarat
                  </td>
               </tr>
               <tr>
                  <td id="td2">
                     Madhya Pradesh
                  </td>
                  <td id="td2">
                  </td>
               </tr>
               <tr>
                  <td colspan="3" style="padding:0">
                     <a class="emailText" href="mailto:cro.iic.mic@aicte-india.org">cro.iic.mic@aicte-india.org</a>
                  </td>
               </tr>
               <tr>
                  <td colspan="3" style="padding:0">
                        <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
                  </td>
               </tr>
              </table>
              
             </div>
          </div>
        </div>

         <div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                
                 <div class="card profile-card-5">
                   <div class="card-img-block">
                     <h5 id="spacc2">Western/WRO</h5>
                     <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. SurendarR"></div>
                   </div>
                   <div class="card-body pt-0">
                     <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Surendar R</h5>
                     <p class="text-center cardSmallFont" style="color:#c71e23;">
                        <b>wro.iic.mic@aicte-india.org <br>
                       
                        011 2958 1337
                        </b>
                     </p>
                   </div>
                 </div>
               </div>
               <div class="card back">
                
                 <table>
                   <tr class="btn-primary-table">
                      <td id="td1">
                        <b id="font1"> Zone </b>
                      </td>
                      <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                      </td>
                   </tr>
                   
                   <tr>
                     <td rowspan="2" id="td4">
                        Western/WRO
                     </td>
                     <td id="td2">
                        Goa
                     </td>
                     <td id="td2">
                        Maharashtra
                     </td>
                   </tr>
                   <tr >
                     <td id="td2">
                        Dadra and Nagar Haveli
                     </td>
                     <td id="td2">
                        Daman and Diu
                     </td>
                   </tr>
                   <tr>
                     <td colspan="3" style="padding:0">
                        <a class="emailText" href="mailto:wro.iic.mic@aicte-india.org">wro.iic.mic@aicte-india.org</a>
                     </td>
                  </tr>
                  <tr>
                     <td colspan="3" style="padding:0">
                           <a class="phoneText" href="tel:011 2958 1235">011 2958 1337</a>
                     </td>
                  </tr>
                 </table>
                
               </div>

            </div>
          </div>

          <div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                 <div class="card profile-card-5">
                   <div class="card-img-block">
                     <h5 id="spacc2">Eastern/ERO</h5>
                     <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
                   </div>
                   <div class="card-body pt-0">
                     <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Udyan Maurya</h5>
                     <p class="text-center cardSmallFont" style="color:#c71e23;">
                        <b>   ero.iic.mic@aicte-india.org<br>
                        011 2958 1240
                        </b>
                     </p>
                   </div>
                 </div>

               </div>
               <div class="card back">
                 <table>
                   <tr class="btn-primary-table">
                   <td id="td1">
                     <b id="font1"> Zone </b>
                   </td>
                   <td id="td1" colspan="3">
                     <b id="font1"> State
                     </b>
                   </td>
                   </tr>
                   <tr>
                        <td height="258" rowspan="6" id="td4">
                           Eastern/ERO
                        </td>
                        <td id="td2">
                           Andaman and Nicobar Islands
                        </td>
                        <td id="td2">
                           Arunachal Pradesh
                        </td>
                      </tr>
                      <tr >
                        <td id="td2">
                           Assam
                        </td>
                        <td id="td2">
                           Jharkhand
                        </td>
                      </tr>
                      <tr>
                        <td id="td2">
                           Manipur
                        </td>
                        <td id="td2">
                           Meghalaya
                        </td>
                      </tr>
                      <tr>
                        <td id="td2">
                           Mizoram
                        </td>
                        <td id="td2">
                           Nagaland
                        </td>
                      </tr>
                      <tr>
                        <td id="td2">
                           Odisha/Orissa
                        </td>
                        <td id="td2">
                           Sikkim
                        </td>
                      </tr>
                      <tr >
                        <td id="td2">
                           Tripura
                        </td>
                        <td id="td2">
                           West Bengal
                        </td>
                      </tr>
                     <tr>
                        <td colspan="3" style="padding:0">
                           <a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
                        </td>
                     </tr>
                     <tr>
                        <td colspan="3" style="padding:0">
                              <a class="phoneText" href="tel:011 2958 1240">011 2958 1240</a>
                        </td>
                     </tr>
                 </table>
               </div>
            </div>
          </div>


          <div class="col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                 <div class="card profile-card-5">
                   <div class="card-img-block">
                     <h5 id="spacc2">South-Central/SCRO</h5>
                     <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. Surendar R"></div>
                   </div>
                   <div class="card-body pt-0">
                     <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Surendar R</h5>
                     <p class="text-center cardSmallFont" style="color:#c71e23;">
                        <b>   scro.iic.mic@aicte-india.org <br>
                        011 2958 1337
                        </b>
                     </p>
                   </div>
                 </div>

               </div>
               <div class="card back">
                
                 <table>
                   <tr class="btn-primary-table">
                      <td id="td1">
                        <b id="font1"> Zone </b>
                      </td>
                      <td id="td1" colspan="2">
                        <b id="font1"> State
                        </b>
                      </td>
                   </tr>
                   
                   <tr>
                     <td>
                        South-Central/SCRO 
                     </td>
                     <td>
                         Andhra Pradesh
                     </td>
                     <td>
                        Telangana
                     </td>
                   </tr>
                  
                   <tr>
                     <td colspan="3" style="padding:0">
                        <a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
                     </td>
                  </tr>
                  <tr>
                     <td colspan="3" style="padding:0">
                           <a class="phoneText" href="tel:011 2958 1326">011 2958 1337</a>
                     </td>
                  </tr>
                 </table>
                
               </div>
            </div>
         </div>

      </div>
   </div>
</section> -->

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
