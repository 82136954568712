import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteIdeaSubmissionDialog21Component } from './institute-idea-submission-dialog/institute-idea-submission-dialog.component';
import { InstituteEvaluatorAssignDialogComponent } from '../institute-evaluator-assign-dialog/institute-evaluator-assign-dialog.component';
import { InstituteTeamLeadVerifyDialogComponent } from '../institute-team-lead-verify-dialog/institute-team-lead-verify-dialog.component';
import { InstituteEvaluationDetailsComponent } from '../institute-evaluation-details/institute-evaluation-details.component';

import { saveAs as importedSaveAs } from 'file-saver';
import * as myGlobals from '../../../../../app.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-idea-submission-21',
  templateUrl: './institute-idea-submission.component.html',
  styleUrls: ['./institute-idea-submission.component.css']
})
export class InstituteIdeaSubmission21Component implements OnInit {

  public ideaNomantion: FormGroup;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  // baseUrlIdea = "https://mic.gov.in/innovationcontest/";
  baseUrlIdea = myGlobals.baseImageUrl;
  ideaSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  total_count:number = 0;
  shotToken:any;
  iic_id:any;
  yukti_challenges:any;
  academicYear : any;
  baseUrl = myGlobals.baseUrl;
  ForWhom: any;
  selected = '2024-25';

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private innovationService: InstituteInnovationContestService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    this.academicYear = '2024-25';
    this.iic_id='';

    this.getIdeaSubmission(this.academicYear);
    this.yuktiChallenge();
    this.shotToken=localStorage.getItem('token');

    this.innovationService.getStatusCounts21('idea').subscribe(
      data => this.counts = data['idea']['0']
    );

    this.ideaNomantion = this.fb.group({
      challenge_id: [null],
      innovation_id: [null],
    });
  }

  getIdeaSubmission(ay) {
    this.innovationService.getIdea21Submission(this.currentPageNo,this.per_page,ay).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.ideaSubmission = [];

    data['data'].forEach((element) => {
      this.ideaSubmission.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }


  openEvaluationDialog(data) {
    const dialogRef = this.dialog.open(InstituteEvaluatorAssignDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getIdeaSubmission(this.academicYear);
    });
  }

  openEvaluationDialogTeamData(data) {
    const dialogRef = this.dialog.open(InstituteTeamLeadVerifyDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }

  openEvaluationViewDialog(data) {
    const dialogRef = this.dialog.open(InstituteEvaluationDetailsComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getIdeaSubmission(this.academicYear);
    });
  }


  openDialog(data) {
    const dialogRef = this.dialog.open(InstituteIdeaSubmissionDialog21Component, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getIdeaSubmission(this.academicYear);
    });
  }

  openFileToDownload(id)
  {
    window.open(this.baseUrl+'/DownloadInnovationDetails?proto_id='+id+'&token='+this.shotToken);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs(data, fileName);
  }



  DeleteIdeaFromVerification(data, title) {
    if (confirm("Are you sure want to delete "+title) == true) {
      this.innovationService.getDeleteIdeaFromVerification(data).subscribe(
        data => this.handleResponseDelete(data)
      );
    }


  }

  handleResponseDelete(data) {

    if(data["status"]==1)
    {
      this.toastr.success(data["msg"]);
      this.getIdeaSubmission(this.academicYear);

      this.innovationService.getStatusCounts21('idea').subscribe(
        data => this.counts = data['idea']['0']
      );

    }
    else
    {
      this.toastr.error(data["msg"]);
    }

  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	  this.per_page = e.pageSize;
    if (this.filterModel == "All") {
      this.getIdeaSubmission(this.academicYear);
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.currentPageNo--;
    if (this.filterModel == "All") {
      this.getIdeaSubmission(this.academicYear);
    } else {
      this.changeFilter();
    }
  }


  

  changeFilter() {
    if (this.filterModel == "All") {
      this.getIdeaSubmission(this.academicYear);
    } else {
      this.innovationService.getIdeaSubmission21Filter(this.currentPageNo, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }
  }

  yuktiChallenge() {
      this.innovationService.getYuktiChallengeDetail().subscribe(
        data => this.handleChallengeResponse(data)
      );
    
  }

  handleChallengeResponse(data){
    this.yukti_challenges = data;
  }
  handleChallengeSubmitResponse(data){
    this.toastr.success('Nominate Sucessfully');
    // this.yukti_challenges = data;
  }

  onSubmit(value) {
    var formData = new FormData();

    formData.append('challenge_id', this.ideaNomantion.get('challenge_id').value);
    formData.append('innovation_id', this.ideaNomantion.get('innovation_id').value);
    this.innovationService.yuktiChallengeNominateDetail(formData).subscribe(
      data => this.handleChallengeSubmitResponse(data)
    );
  }

  onAYChange(event){
    let ay = event.value;
    this.academicYear = ay;
    this.getIdeaSubmission(ay);
  }

}
