import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-bifurcation-score',
  templateUrl: './bifurcation-score.component.html',
  styleUrls: ['./bifurcation-score.component.css']
})
export class BifurcationScoreComponent implements OnInit {
  formData: any;
  currentData: any;
  ayList : any;
  ay201920 : number = 0;
  ay202021 : number = 0;
  ay202122 : number = 0;
  ay202223 : number = 0;
  ay202324 : number = 0;
  constructor(private instituteMyCouncilService: InstituteMyCouncilService) {

  }

  // constructor(private instituteMyCouncilService: InstituteMyCouncilService) {
  //   this.instituteMyCouncilService
  //     .getRewardPoints()
  //     .subscribe((data) => this.handleResponse(data));
  // }


  ngOnInit(): void {
      this.instituteMyCouncilService
      .getRatingAccouncedYear()
      .subscribe((data) => this.handleResponse(data));
    

  }

  handleResponse(data){
    this.ayList = data;
    console.log(this.ayList);
    for(const val of this.ayList){
      if(val?.acadmic_year == '2019-20'){
        this.ay201920 = 1;
      }
      if(val?.acadmic_year == '2020-21'){
        this.ay202021 = 1;
      }
      if(val?.acadmic_year == '2021-22'){
        this.ay202122 = 1;
      }
      if(val?.acadmic_year == '2022-23'){
        this.ay202223 = 1;
      }
      if(val?.acadmic_year == '2023-24'){
        this.ay202324 = 1;
      }
    }
  }



}
