import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-academic-year-performance202324',
  templateUrl: './academic-year-performance202324.component.html',
  styleUrls: ['./academic-year-performance202324.component.css']
})
export class AcademicYearPerformance202324Component implements OnInit {

  formData: any;
  fStar:any;
  fstarScore:any;
  fourthStarScore:any;

  iaUR : number =0 ;
  sihIdeaTotal : number =0 ;

  constructor(private instituteMyCouncilService: InstituteMyCouncilService) { }

  ngOnInit(): void {

    this.instituteMyCouncilService
      .getRewardPointsCurrentAyear202324('2023-24')
      .subscribe((data) => this.handleResponse1(data));
  }

  handleResponse1(data) {
    this.formData = data['data'];
    this.fStar = data['data']['FStar'];
    this.fstarScore = data['data']['FStarScore'];
    this.fourthStarScore = data['data']['fouthStar'];
    this.iaUR = Number(this.fstarScore?.ia_reskilling_score) + Number(this.fstarScore?.ia_upskilling_score);
    this.sihIdeaTotal = Number(this.fstarScore?.yukti_idea_participation_in_SIH_score) + Number(this.fstarScore?.yukti_idea_selected_in_SIH_score);
  }

}
