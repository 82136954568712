<link
  rel="stylesheet"
  id="skilled-style-css"
  href="/assets/assets/css/customAll.css"
  type="text/css"
  media="all"
/>
<link
  rel="stylesheet"
  id="skilled-style-css"
  href="/assets/assets/css/style.css"
  type="text/css"
  media="all"
/>
<link
  rel="stylesheet"
  id="skilled-style-css"
  href="/assets/assets/css/responsive.css"
  type="text/css"
  media="all"
/>

<div class="d-block">
  <div class="header-mobile">
    <nav class="navbar navbar-expand-lg navbar-light bg-light1">
      <figure class="wpb_wrapper vc_figure">
        <a routerLink="/"
          ><img
            class="wpb_column vc_column_container vc_col-sm-2 topLogoBG"
            src="assets/assets/images/iiclogo.png"
            alt="Logo"
        /></a>
      </figure>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul id="menu-main-menu" class="respmenu">
          <!-- <li id="menu-item-13647" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item" (click)="message()" ><a routerLink="/">Home</a></li> -->
          <li
            id="menu-item-13647"
            class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
          >
            <a routerLink="/">Home</a>
          </li>
          <li
            id="menu-item-13486"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
          >
            <a href="javascript: void(0)"
              >About Us <i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
            <ul class="sub-menu">
              <li
                id="menu-item-13624"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
              >
                <a routerLink="/iicabout-mic">About MIC</a>
              </li>
              <!-- <li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/iicadvisory-committee">Advisory Committee</a></li> -->
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic-implementation-team">Implementation Team</a>
              </li>
            </ul>
          </li>
          <li
            id="menu-item-13647"
            class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
          >
            <!-- <a routerLink="/iic202223-calender">IIC 2023-23 <i class="fa fa-caret-down" aria-hidden="true"></i></a> -->
            <!-- <a href="assets/announcements/iic-calendar-and-celebration-activity.pdf" target="_blank">IIC Calender 2023</a> -->
            <a
              href="{{
                imageBasePath
              }}assets/announcements/IIC-Calender-and-Celebration-Acticity.pdf"
              target="_blank"
              >IIC Calender 2023</a
            >
            <ul class="sub-menu">
              <!-- <li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"><a routerLink="/iic202122-calender">IIC Calender 2021</a></li> -->
              <li
                id="menu-item-13624"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
              >
                <a
                  href="{{
                    imageBasePath
                  }}assets/announcements/IIC5.0-Calander-Activity-2022-23.pdf"
                  target="_blank"
                  >IIC Calender Activities 2023</a
                >
              </li>
              <li
                id="menu-item-13624"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
              >
                <a
                  href="{{
                    imageBasePath
                  }}assets/announcements/IIC5.0-Celebration-Activity-2022-23.pdf"
                  target="_blank"
                  >IIC Celebration Activities 2023</a
                >
              </li>
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/">List of Approved Institutes</a>
              </li>
              <!-- <li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/iic_rating_2018_19">IIC Ratings 2018-19</a></li>	-->
            </ul>
          </li>
          <li
            id="menu-item-13486"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
          >
            <a href="javascript: void(0)"
              >At a Glance <i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
            <ul class="sub-menu">
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic-impact-dashboard">IIC Impact</a>
              </li>
              <li
                id="menu-item-13624"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
              >
                <a routerLink="/iic-dashboard1819">IIC 2018-19</a>
              </li>
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic-dashboard1920">IIC 2019-20</a>
              </li>
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic202021-calender">IIC 2020-21</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13637"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                  >
                    <a routerLink="/iic-ratings2021">Annual Performance</a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-13642"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
              >
                <a routerLink="/iic202122-calender">IIC 2021-22</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13642"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
                  >
                    <a href="/iic-ratings2022">Annual Performance 123</a>
                  </li>
                  <li
                    id="menu-item-13642"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
                  >
                    <a
                      target="_blank"
                      href="{{
                        imageBasePath
                      }}assets/announcements/Annual-Report-2021-22.pdf"
                      >Annual Report</a
                    >
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-13645"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13645"
              >
                <a routerLink="/iic202223-calender">IIC 2022-23</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13645"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13645"
                  >
                    <a href="/iic-ratings2023">Annual Performance</a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-13646"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13646"
              >
                <a href="javascript:void(0)">IIC 2023-24</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13646"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13646"
                  >
                    <a href="/iic-ratings2024">Annual Performance</a>
                  </li>
                </ul>
              </li>
              <!-- <li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/iic-impact-dashboard">IIC Impact Dashboard</a></li> -->
              <!-- <li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/iic_rating_2018_19">IIC Ratings 2018-19</a></li>	-->
            </ul>
          </li>

          <li
            id="menu-item-13486"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
          >
            <a href="javascript: void(0)"
              >Major Activities
              <i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
            <ul class="sub-menu">
              <li
                id="menu-item-13647"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
              >
                <a routerLink="/iic-innovation-ambassador2021"
                  >Innovation Ambassador</a
                >
                <!-- <ul class="sub-menu"> -->
                <!--<li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"><a routerLink='/iic-innovation-ambassador'>IA 2019-20</a></li> -->
                <!-- <li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/iic-innovation-ambassador2021'>IA 2020-21</a></li>
									</ul> -->
              </li>
              <li
                id="menu-item-13637"
                class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/impect-lecture-series2023"
                  >Impact Lecture Series</a
                >
                <!-- <ul class="sub-menu">
										<li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"><a routerLink='/iic201920'>IIC 2019-20</a></li>
										<li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/impect-lecture-series2021'>IIC 2020-21</a></li>
										<li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/impect-lecture-series2023'>IIC 6.0 (2022-2023)</a></li>
									</ul> -->
              </li>
              <li
                id="menu-item-13647"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
              >
                <a routerLink="/iic-online-sessions">Online Session</a>
              </li>
              <li
                id="menu-item-13486"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
              >
                <a href="javascript: void(0)">National Innovation Contest</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13624"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                  >
                    <a routerLink="/poc-contest2019">PoC Contest 2019</a>
                  </li>
                  <li
                    id="menu-item-13637"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                  >
                    <a routerLink="/innovation-conest2020"
                      >Innovation Contest 2020</a
                    >
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-13486"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
              >
                <a href="javascript: void(0)">International Exposure</a>
                <ul class="sub-menu">
                  <li
                    id="menu-item-13624"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                  >
                    <a routerLink="/india-canada">India-Canada</a>
                  </li>
                  <li
                    id="menu-item-13637"
                    class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                  >
                    <a routerLink="/india-south-korea">India-South Korea</a>
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-13647"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
              >
                <a routerLink="/mentor-mentee-program">Mentor Mentee Program</a>
              </li>
            </ul>
          </li>

          <li
            id="menu-item-13486"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
          >
            <a href="javascript: void(0)"
              >Resources & Referrals
              <i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
            <ul class="sub-menu">
              <li
                id="menu-item-13647"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
              >
                <a routerLink="/incubatorstab"
                  >List of Pre-Incubators & Incubators</a
                >
              </li>
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic-ambassador-profile"
                  >List of Innovation Ambassadors</a
                >
              </li>
              <li
                id="menu-item-13637"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/iic-download">Download</a>
              </li>
              <li
                id="menu-item-13637"
                class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a href="assets/html/index.html" target="_blank"
                  >IIC Guide and Portal Manual</a
                >
              </li>
            </ul>
          </li>

          <!-- <li id="menu-item-13638" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13638 msm-top-level-item"><a routerLink="/iic-contact-us">contact</a></li> -->
          <li
            id="menu-item-13638"
            class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13638 msm-top-level-item"
          >
            <a routerLink="/iic-notification">Notification</a>
          </li>

          <li
            id="menu-item-13486"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
          >
            <a href="javascript: void(0)"
              >Gallery <i class="fa fa-caret-down" aria-hidden="true"></i
            ></a>
            <ul class="sub-menu">
              <li
                id="menu-item-13647"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
              >
                <a routerLink="/gallery">Photo/video/Media Gallery</a>
              </li>
              <!-- <li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/gallery/video-gallery">Video Gallery</a></li>
									<li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/gallery/media">Media</a></li> -->
              <li
                id="menu-item-13638"
                class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
              >
                <a routerLink="/ntw-2023">National Technology Week 2023</a>
              </li>
            </ul>
          </li>

          <li
            id="menu-item-13638"
            class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13638 msm-top-level-item"
          >
            <a routerLink="/about-us/contact_us">Contact Us</a>
          </li>
        </ul>

        <ul id="wp-login-btn" class="sf-menu wh-menu-main">
          <li class="loginButHeader">
            <a href="/login" target="_blank">Login</a>
          </li>
        </ul>
        <ul class="sf-menu wh-menu-main sf-menu wh-menu-main-login">
          <li class="loginButHeader">
            <a href="/yir-dashboard" target="_blank"
              >YUKTI Innovation Repository</a
            >
          </li>
        </ul>
        <!-- <ul id="wp-login-btn1" class="sf-menu wh-menu-main">
							<li class="loginButHeader"><a href="/signup" target="_blank">Signup</a></li>
						</ul> -->
        <ul
          id="menu-main-menu"
          class="sf-menu wh-menu-main sf-menu wh-menu-main-login"
          style="background-color: #2d3091"
        >
          <li class="loginButHeader">
            <a routerLink="/iic-impact-dashboard">IIC Impact</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="d-none d-lg-block">
  <div class="cbp-row wh-header top wh-sticky-header-enabled">
    <div
      data-vc-full-width="true"
      data-vc-full-width-init="false"
      data-vc-stretch-content="true"
      class="vc_row wpb_row vc_row-fluid overflow-visible vc_custom_1499246862736 vc_row-has-fill"
    >
      <div class="wpb_column vc_column_container vc_col-sm-2 topLogoBG">
        <div class="vc_column-inner vc_custom_1497355882172">
          <div class="wpb_wrapper">
            <div
              class="wpb_single_image wpb_content_element vc_align_left vc_custom_1499082227376"
            >
              <figure class="wpb_wrapper vc_figure">
                <a routerLink="/"
                  ><img
                    class="vc_single_image-img topLogo"
                    src="assets/assets/images/iiclogo.png"
                    alt="Logo"
                /></a>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="width-auto wpb_column vc_column_container vc_col-sm-8">
        <div class="vc-meain-heading">
          <h4 class="moe-text">MoE's Innovation Cell</h4>
          <h2>Institution's Innovation Council</h2>
        </div>
      </div>
      <div
        class="width-auto pull-right wpb_column vc_column_container vc_col-sm-2"
      >
        <div class="vc_column-inner vc_custom_1499247879465">
          <div class="wpb_wrapper micLogoTop">
            <div class="logoRightBox">
              <figure class="logoRightFigure">
                <a href="https://mic.gov.in/" target="_blank"
                  ><img
                    class="mic-logo"
                    src="assets/assets/images/mic-logo.png"
                    alt="Logo"
                /></a>
              </figure>
            </div>
            <!-- <div class="vc_icon_element vc_icon_element-outer vc_custom_1495712065253 pull-left vc_icon_element-align-center vc_icon_element-have-style">
						<div class="vc_icon_element-inner vc_icon_element-color-white vc_icon_element-have-style-inner vc_icon_element-size-xs vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-custom"  style="background-color:#55acee"><span class="vc_icon_element-icon fa fa-twitter" ></span><a class="vc_icon_element-link" href="https://twitter.com/mhrd_innovation"  title="" target="_self"></a></div>
					</div>
					<div class="vc_icon_element vc_icon_element-outer vc_custom_1495712057678 pull-left vc_icon_element-align-center vc_icon_element-have-style">
						<div class="vc_icon_element-inner vc_icon_element-color-white vc_icon_element-have-style-inner vc_icon_element-size-xs vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-blue" ><span class="vc_icon_element-icon fa fa-facebook" ></span><a class="vc_icon_element-link" href="https://www.facebook.com/mhrdInnovation/"  title="" target="_self"></a></div>
					</div>
					<div class="vc_icon_element vc_icon_element-outer vc_custom_1495712050544 pull-left vc_icon_element-align-center vc_icon_element-have-style">
						<div class="vc_icon_element-inner vc_icon_element-color-white vc_icon_element-have-style-inner vc_icon_element-size-xs vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-custom"  style="background-color:#e52d27"><a class="vc_icon_element-link" href="https://www.youtube.com/mhrdinnovationcell"  title="" target="_self"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></div>
					</div>
					<div class="vc_icon_element vc_icon_element-outer vc_custom_1495712042896 pull-left vc_icon_element-align-center vc_icon_element-have-style">
						<div class="vc_icon_element-inner vc_icon_element-color-white vc_icon_element-have-style-inner vc_icon_element-size-xs vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-custom"  style="background-color:#dd4b39"><a class="vc_icon_element-link" href="https://www.instagram.com/mhrd.innovationcell/"  title="" target="_self"><i class="fa fa-instagram" aria-hidden="true"></i></a></div>
					</div> -->
          </div>
        </div>
      </div>
      <!-- <div class="width-auto pull-right sk-accent-text wpb_column vc_column_container vc_col-sm-2 vc_hidden-md vc_hidden-sm vc_hidden-xs">
			<div class="vc_column-inner vc_custom_1499247888242">
				<div class="wpb_wrapper">
					<h3 style="font-size: 12px;color: javascript: void(0)666667;line-height: 20px;text-align: center" class="vc_custom_heading vc_custom_1555408991145" ><em>FREE CALL:</em> 888-234-45</h3>
					<h3 style="font-size: 12px;color: javascript: void(0)666667;line-height: 20px;text-align: center" class="vc_custom_heading vc_custom_1499247185503" ><em>EMAIL US:</em> info[at]coach.com</h3>
				</div>
			</div>
		</div> -->
    </div>
    <div class="vc_row-full-width vc_clearfix"></div>
  </div>

  <div class="cbp-row wh-header top second-nav inner-navbar">
    <div
      data-vc-full-width="true"
      data-vc-full-width-init="false"
      data-vc-stretch-content="true"
      class="vc_row wpb_row vc_row-fluid overflow-visible vc_custom_1499246862736 vc_row-has-fill"
    >
      <div
        class="width-auto wpb_column vc_column_container vc_col-sm-12 wp-inner-nav"
      >
        <div class="vc_column-inner vc_custom_1499247862475">
          <div class="wpb_wrapper">
            <div
              id="cbp-menu-main"
              class="cbp-container vc_pull-left vc_custom_1497448436115"
            >
              <ul id="menu-main-menu" class="sf-menu wh-menu-main wp-sec-nav">
                <li
                  id="menu-item-13647"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                >
                  <a routerLink="/" *ngIf="!hasRoute('/')">Home</a>
                </li>
                <li
                  id="menu-item-13486"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                >
                  <a href="javascript: void(0)"
                    >About Us <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <li
                      id="menu-item-13624"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                    >
                      <a routerLink="/iicabout-mic">About MIC</a>
                    </li>
                    <!-- <li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/iicadvisory-committee">Advisory Committee</a></li> -->
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/iic-implementation-team"
                        >Implementation Team</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-13647"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                >
                  <!-- <a routerLink="/" href="javascript:void(0);">IIC 2022-23 <i class="fa fa-caret-down" aria-hidden="true"></i></a> -->
                  <a routerLink="/" href="javascript:void(0);"
                    >IIC 2024-25
                    <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <!-- <li id="menu-item-13624" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"><a routerLink="/iic202223-calender">IIC Calender 2022</a></li> -->
                    <!-- <li id="menu-item-13624" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624">
								<a href="assets/announcements/iic-calendar-and-celebration-activity.pdf" target="_blank">IIC Calender 2023</a>
								<a href="assets/announcements/IIC-Calender-and-Celebration-Acticity.pdf" target="_blank">IIC Calender 2023</a>
							</li> -->
                    <!-- <li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624">
								<a href="assets/announcements/IIC5.0-Calander-Activity-2022-23.pdf" target="_blank">IIC Calender Activities 2023</a>
							</li>		
							<li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624">
								<a href="assets/announcements/IIC5.0-Celebration-Activity-2022-23.pdf" target="_blank">IIC Celebration Activities 2023</a>
							</li>
							<li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/institute-list">List of Approved Institutes</a></li> -->

                    <li
                      id="menu-item-13624"
                      class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                    >
                      <a routerLink="/iic_calendar_2024-25"
                        >IIC Calendar 2024-25</a
                      >
                    </li>

                    <!--<li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624">
								<a href="{{ imageBasePath }}assets/announcements/1.pdf" target="_blank">IIC Calender Activities 2024</a>
							</li>		
							<li id="menu-item-13624" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-page menu-item-13624">
								<a href="{{ imageBasePath }}assets/announcements/2.pdf" target="_blank">IIC Celebration Activities 2024</a>
							</li> -->
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/institute-list"
                        >List of Approved Institutes</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-13486"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                >
                  <a href="javascript: void(0)"
                    >At a Glance
                    <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <!-- <li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/iic-impact-dashboard'>Achievement & Impact</a></li> -->
                    <li
                      id="menu-item-13624"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                    >
                      <a routerLink="/iic-dashboard1819">IIC 2018-19</a>
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/iic-dashboard1920">IIC 2019-20</a>
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/iic202021-calender">IIC 2020-21</a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13637"
                          class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                        >
                          <a routerLink="/iic-ratings2021"
                            >Annual Performance</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-13642"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
                    >
                      <a routerLink="/iic202122-calender">IIC 2021-22</a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13642"
                          class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
                        >
                          <a href="/iic-ratings2022">Annual Performance</a>
                        </li>
                        <li
                          id="menu-item-13642"
                          class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13642"
                        >
                          <a
                            target="_blank"
                            href="{{
                              imageBasePath
                            }}assets/announcements/Annual-Report-2021-22.pdf"
                            >Annual Report</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-13645"
                      class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13645"
                    >
                      <a routerLink="/iic202223-calender">IIC 2022-23</a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13645"
                          class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13645"
                        >
                          <a href="/iic-ratings2023">Annual Performance</a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-13646"
                      class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13646"
                    >
                      <a href="javascript:void(0)">IIC 2023-24</a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13646"
                          class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13646"
                        >
                          <a href="/iic-ratings2024">Annual Performance</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li
                  id="menu-item-13486"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                >
                  <a href="javascript: void(0)"
                    >Major Activities
                    <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/iic-innovation-ambassador2021"
                        >Innovation Ambassador</a
                      >
                      <!-- <ul class="sub-menu">
								
								<li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/iic-innovation-ambassador2021'>IA 2020-21</a></li>
							</ul> -->
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/impect-lecture-series2023"
                        >Impact Lecture Series</a
                      >
                      <!-- <ul class="sub-menu">
								<li id="menu-item-13624" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"><a routerLink='/iic201920'>IIC 2019-20</a></li>
								<li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/impect-lecture-series2021'>IIC 2020-21</a></li>
								<li id="menu-item-13637" class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink='/impect-lecture-series2023'>IIC 6.0 (2022-2023)</a></li>
							</ul> -->
                    </li>
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/iic-online-sessions">Online Session</a>
                    </li>
                    <li
                      id="menu-item-13486"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                    >
                      <a href="javascript: void(0)"
                        >National Innovation Contest</a
                      >
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13624"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                        >
                          <a routerLink="/poc-contest2019">PoC Contest 2019</a>
                        </li>
                        <li
                          id="menu-item-13637"
                          class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                        >
                          <a routerLink="/innovation-conest2020"
                            >Innovation Contest 2020</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-13486"
                      class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                    >
                      <a href="javascript: void(0)">International Exposure</a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-13624"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13624"
                        >
                          <a routerLink="/india-canada">India-Canada</a>
                        </li>
                        <li
                          id="menu-item-13637"
                          class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                        >
                          <a routerLink="/india-south-korea"
                            >India-South Korea</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/mentor-mentee-program"
                        >Mentor Mentee Program</a
                      >
                    </li>

                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a href="https://yukti.mic.gov.in/" target="_blank"
                        >YUKTI Innovation Repository</a
                      >
                    </li>
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a
                        href="{{
                          baseUrl
                        }}/uploads/institutes/mic/images/announcements/yic2023_guidlines.pdf"
                        target="_blank"
                        >YUKTI Innovation Challenge 2023</a
                      >
                    </li>
                    <li
                      id="menu-item-13648"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/national-technology-week"
                        >National Technology Week 2023</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  id="menu-item-13486"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                >
                  <a href="javascript: void(0)"
                    >Resources & Referrals
                    <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/incubatorstab"
                        >List of Pre-Incubators & Incubators</a
                      >
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/iic-ambassador-profile"
                        >List of Innovation Ambassadors</a
                      >
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/iic-download">Download</a>
                    </li>
                    <li
                      id="menu-item-13637"
                      class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a href="assets/html/index.html" target="_blank"
                        >IIC Guide and Portal Manual</a
                      >
                    </li>
                  </ul>
                </li>

                <li
                  id="menu-item-13638"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13638 msm-top-level-item"
                >
                  <a routerLink="/iic-notification">Notification</a>
                </li>

                <li
                  id="menu-item-13486"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-13486 msm-top-level-item"
                >
                  <a href="javascript: void(0)"
                    >Gallery <i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <ul class="sub-menu">
                    <li
                      id="menu-item-13647"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13647 msm-top-level-item"
                    >
                      <a routerLink="/gallery">Photo/video/Media Gallery</a>
                    </li>
                    <!-- <li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/gallery/video-gallery">Video Gallery</a></li>
							<li id="menu-item-13637" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"><a routerLink="/gallery/media">Media</a></li> -->
                    <li
                      id="menu-item-13638"
                      class="mobMainMenu menu-item menu-item-type-post_type menu-item-object-post menu-item-13637"
                    >
                      <a routerLink="/ntw-2023"
                        >National Technology Week 2023</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-13638"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13638 msm-top-level-item"
                >
                  <a routerLink="/about-us/contact_us">Contact Us</a>
                </li>
              </ul>
              <ul class="sf-menu wh-menu-main sf-menu wh-menu-main-login">
                <li class="loginButHeader">
                  <a href="/login" target="_blank">Login</a>
                </li>
              </ul>
              <!-- <ul class="sf-menu wh-menu-main sf-menu wh-menu-main-login">
					<li class="loginButHeader"><a href="/signup" target="_blank">Signup</a></li>
				</ul> -->
              <ul class="sf-menu wh-menu-main sf-menu wh-menu-main-login">
                <li class="loginButHeader">
                  <a href="/yir-dashboard" target="_blank"
                    >YUKTI Innovation Repository</a
                  >
                </li>
              </ul>
              <ul
                id="menu-main-menu"
                class="sf-menu wh-menu-main sf-menu wh-menu-main-login"
              >
                <li class="loginButHeader achiImpact">
                  <a routerLink="/iic-impact-dashboard">IIC Impact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
